import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor() {}

  getPurBolSegments() {
    return this.purbol;
  }

  getStatusSegments() {
    return this.status;
  }

  getInvoiceSegments() {
    return this.invoice;
  }

  getStatusCds() {
    return this.statusCodes;
  }

  private purbol = [{
    "name": "ISA Sender",
    "comment": "ISA Sender",
    "segment": "ISA",
    "element": 6,
    "element_real": 7
  },
    {
      "name": "ISA Receiver",
      "comment": "ISA Receiver",
      "segment": "ISA",
      "element": 8,

    },
    {
      "name": "ISA Control#",
      "comment": "ISA Control#",
      "segment": "ISA",
      "element": 13,

    },
    {
      "name": "ISA Date",
      "comment": "ISA Date",
      "segment": "ISA",
      "element": 9,

    },
    {
      "name": "ISA Time",
      "comment": "ISA Time",
      "segment": "ISA",
      "element": 10,

    },
    {
      "name": "GS Sender",
      "comment": "GS Sender",
      "segment": "GS",
      "element": 2,
    },
    {
      "name": "GS Control#",
      "comment": "GS Control#",
      "segment": "GS",
      "element": 6,
    },
    {
      "name": "Shipment ID",
      "comment": "Shipment ID",
      "segment": "B2",
      "element": 4,

    },
    {
      "name": "GS Receiver",
      "comment": "GS Receiver",
      "segment": "GS",
      "element": 3,

    },
    {
      "name": "Purpose CD",
      "comment": "Purpose CD",
      "segment": "B2A",
      "element": 1,

    },
    {
      "name": "PO Number",
      "comment": "PO Number",
      "segment": "OID",
      "element": 1,
    },
    {
      "name": "Order Detail",
      "comment": "Order Detail",
      "segment": "OID",
      "element": 2,
    },
    {
      "name" : "Shipper Name",
      "comment": "shp nm - N102 where n102 = SF",
      "segment": "N1",
      "element": 2,
      "dependent_element": 1,
      "dependent_element_val": 'SF'
    },
    {
      "name": "Shipper Location ID",
      "comment": "shp loc id - n104 where n102 = SF",
      "segment": "N1",
      "element": 4,
      "dependent_element": 1,
      "dependent_element_val": 'SF'

    },
    {
      "name" : "Shipper Address",
      "comment" : "shp add - N301 where N102 = SF",
      "segment" : "N3",
      "element" : 1,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "SF"
    },
    {
      "name" : "Shipper Zip",
      "comment" : "shp zip - N403 where N102 = SF",
      "segment" : "N4",
      "element" : "3",
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "SF"
    },
    {
      "name" : "Shipper State",
      "comment" : "shp st = N402 where N102 = SF",
      "segment" : "N4",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "SF"
    },
    {
      "name" : "Consignee Name",
      "comment" : "cons nm - N102 where N102 = ST",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "ST"
    },
    {
      "name" : "Consignee Location ID",
      "comment" : "cons loc id - N104 where N102 = ST",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "ST"
    },
    {
      "name" : "Consignee Address",
      "comment" : "cons add = N301 where N102 = ST",
      "segment" : "N3",
      "element" : 1,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "ST"
    },
    {
      "name" : "Consignee Zip",
      "comment" : "cons zip = N404 where N102 = ST",
      "segment" : "N4",
      "element" : 3,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "ST"
    },
    {
      "name" : "Consignee State",
      "comment" : "cons st = N402 where N102 = ST",
      "segment" : "N4",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "ST"
    },
    {
      "name" : "Bill-To Name",
      "comment" : "bill-to name = N102 where N102 = BT",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To Address",
      "comment" : "bill-to add = N301 where N102 = BT",
      "segment" : "N3",
      "element" : 1,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To Location ID",
      "comment" : "bill-to loc id = N104 where N102 = BT",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To Zip",
      "comment" : "bill-to zip = N403 where N102 = BT",
      "segment" : "N4",
      "element" : 3,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To State",
      "comment" : "bill-to st = N402 where N102 = BT",
      "segment" : "N4",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    }
  ];

  private status = [{
    "name": "ISA Sender",
    "comment": "ISA Sender",
    "segment": "ISA",
    "element": 6,
    "element_real": 7
  },
    {
      "name": "ISA Receiver",
      "comment": "ISA Receiver",
      "segment": "ISA",
      "element": 8,

    },
    {
      "name": "ISA Control#",
      "comment": "ISA Control#",
      "segment": "ISA",
      "element": 13,

    },
    {
      "name": "ISA Date",
      "comment": "ISA Date",
      "segment": "ISA",
      "element": 9,

    },
    {
      "name": "ISA Time",
      "comment": "ISA Time",
      "segment": "ISA",
      "element": 10,

    },
    {
      "name": "GS Sender",
      "comment": "GS Sender",
      "segment": "GS",
      "element": 2,
    },
    {
      "name": "GS Control#",
      "comment": "GS Control#",
      "segment": "GS",
      "element": 6,
    },
    {
      "name": "Pro#",
      "comment": "Pro Number",
      "segment": "B10",
      "element": 1,
    },
    {
      "name": "Shipment ID",
      "comment": "Shipment ID",
      "segment": "B10",
      "element": 2,

    },
    {
      "name": "Stat-Date",
      "comment": "State Date",
      "segment": "AT7",
      "element": 5,

    },
    {
      "name": "Event Time",
      "comment": "Event Time",
      "segment": "AT7",
      "element": 6,

    },
    {
      "name": "ShipStat-CD",
      "comment": "Shipment Status Code",
      "segment": "AT7",
      "element": 1,

    },
    {
      "name": "AppStat-Reasn",
      "comment": "App Status Reason",
      "segment": "AT7",
      "element": 2,

    },
    {
      "name": "AppStat-Cd",
      "comment": "App Status Cd",
      "segment": "AT7",
      "element": 3,

    },
    {
      "name": "AppStat-Reasn",
      "comment": "App Status Reson2",
      "segment": "AT7",
      "element": 4,

    },
    {
      "name" : "Shipper Name",
      "comment": "shp nm - N102 where n102 = SH",
      "segment": "N1",
      "element": 2,
      "dependent_element": 1,
      "dependent_element_val": 'SH'
    },
    {
      "name": "Shipper Location ID",
      "comment": "shp loc id - n104 where n102 = SH",
      "segment": "N1",
      "element": 4,
      "dependent_element": 1,
      "dependent_element_val": 'SH'

    },
    {
      "name" : "Consignee Name",
      "comment" : "cons nm - N102 where N102 = CN",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "CN"
    },
    {
      "name" : "Consignee Location ID",
      "comment" : "cons loc id - N104 where N102 = CN",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "CN"
    },
    {
      "name" : "Bill-To Name",
      "comment" : "bill-to name = N102 where N102 = BT",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To Location ID",
      "comment" : "bill-to loc id = N104 where N102 = BT",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name": "L11-Ref-List",
      "comment": "L11 Reference List",
      "segment": "L11",
      "element": 1,
      "list" : "true"

    }
  ];

  private invoice = [{
    "name": "ISA Sender",
    "comment": "ISA Sender",
    "segment": "ISA",
    "element": 6,
    "element_real": 7
  },
    {
      "name": "ISA Receiver",
      "comment": "ISA Receiver",
      "segment": "ISA",
      "element": 8,

    },
    {
      "name": "ISA Control#",
      "comment": "ISA Control#",
      "segment": "ISA",
      "element": 13,

    },
    {
      "name": "ISA Date",
      "comment": "ISA Date",
      "segment": "ISA",
      "element": 9,

    },
    {
      "name": "ISA Time",
      "comment": "ISA Time",
      "segment": "ISA",
      "element": 10,

    },
    {
      "name": "GS Sender",
      "comment": "GS Sender",
      "segment": "GS",
      "element": 2,
    },
    {
      "name": "GS Receiver",
      "comment": "GS Receiver",
      "segment": "GS",
      "element": 3,
    },
    {
      "name": "GS Control#",
      "comment": "GS Control#",
      "segment": "GS",
      "element": 6,
    },
    {
      "name": "Pro Number",
      "comment": "Pro#",
      "segment": "B3",
      "element": 2,
    },
    {
      "name": "Shipment ID",
      "comment": "Shipment ID",
      "segment": "B3",
      "element": 3,
    },
    {
      "name": "Inv Terms",
      "comment": "Inv Terms",
      "segment": "B3",
      "element": 4,
    },
    {
      "name": "Invoice Total",
      "comment": "Invoice Total",
      "segment": "B3",
      "element": 7,
    },
    {
      "name": "Inv Type",
      "comment": "Inv Type",
      "segment": "B3",
      "element": 8,
    },

    {
      "name" : "Shipper Name",
      "comment": "shp nm - N102 where n102 = SH",
      "segment": "N1",
      "element": 2,
      "dependent_element": 1,
      "dependent_element_val": 'SH'
    },
    {
      "name": "Shipper Location ID",
      "comment": "shp loc id - n104 where n102 = SH",
      "segment": "N1",
      "element": 4,
      "dependent_element": 1,
      "dependent_element_val": 'SH'

    },
    {
      "name" : "Consignee Name",
      "comment" : "cons nm - N102 where N102 = CN",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "CN"
    },
    {
      "name" : "Consignee Location ID",
      "comment" : "cons loc id - N104 where N102 = CN",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "CN"
    },
    {
      "name" : "Bill-To Name",
      "comment" : "bill-to name = N102 where N102 = BT",
      "segment" : "N1",
      "element" : 2,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name" : "Bill-To Location ID",
      "comment" : "bill-to loc id = N104 where N102 = BT",
      "segment" : "N1",
      "element" : 4,
      "dependent_segment" : "N1",
      "dependent_element": 1,
      "dependent_element_val" : "BT"
    },
    {
      "name": "N9 Ref List",
      "comment": "N9 Ref List",
      "segment": "N9",
      "list": "true"
    }
  ];


  private statusCodes = [
    {"statCd": "A3",  "reason" : "Shipment Returned to Shipper"},
    {"statCd": "A7",  "reason" : "Refused by Consignee"},
    {"statCd": "A9",  "reason" : "Shipment Damaged"},
    {"statCd": "AF",  "reason" : "Carrier Departed Pick-up Location with Shipment"},
    {"statCd": "AG",  "reason" : "Estimated Delivery"},
    {"statCd": "AH",  "reason" : "Attempted Delivery"},
    {"statCd": "AI",  "reason" : "Shipment has been Reconsigned"},
    {"statCd": "AJ",  "reason" : "Tendered for Delivery"},
    {"statCd": "AM",  "reason" : "Loaded on Truck"},
    {"statCd": "AN",  "reason" : "Diverted to Air Carrier"},
    {"statCd": "AP",  "reason" : "Delivery Not Completed"},
    {"statCd": "AR",  "reason" : "Rail Arrival at Destination Intermodal Ramp"},
    {"statCd": "AV",  "reason" : "Available for Delivery"},
    {"statCd": "B6",  "reason" : "Estimated to Arrive at Carrier Terminal"},
    {"statCd": "BA",  "reason" : "Connecting Line or Cartage Pick-up"},
    {"statCd": "BC",  "reason" : "Storage in Transit"},
    {"statCd": "C1",  "reason" : "Estimated to Depart Terminal Location"},
    {"statCd": "CA",  "reason" : "Shipment Cancelled"},
    {"statCd": "CD",  "reason" : "Carrier Departed Delivery Location"},
    {"statCd": "CL",  "reason" : "Trailer Closed Out"},
    {"statCd": "CP",  "reason" : "Completed Loading at Pick-up Location"},
    {"statCd": "D1",  "reason" : "Completed Unloading at Delivery Location"},
    {"statCd": "I1",  "reason" : "In-Gate"},
    {"statCd": "J1",  "reason" : "Delivered to Connecting Line"},
    {"statCd": "K1",  "reason" : "Arrived at Customs"},
    {"statCd": "L1",  "reason" : "Loading"},
    {"statCd": "OA",  "reason" : "Out-Gate"},
    {"statCd": "OO",  "reason" : "Paperwork Received - Did not Receive Shipment or Equipment"},
    {"statCd": "P1",  "reason" : "Departed Terminal Location"},
    {"statCd": "PR",  "reason" : "U.S. Customs Hold at In-Bond Location"},
    {"statCd": "R1",  "reason" : "Received from Prior Carrier"},
    {"statCd": "RL",  "reason" : "Rail Departure from Origin Intermodal Ramp"},
    {"statCd": "S1",  "reason" : "Trailer Spotted at Consignee's Location"},
    {"statCd": "SD",  "reason" : "Shipment Delayed"},
    {"statCd": "X1",  "reason" : "Arrived at Delivery Location"},
    {"statCd": "X2",  "reason" : "Estimated Date and/or Time of Arrival at Consignee's Location"},
    {"statCd": "X3",  "reason" : "Arrived at Pick-up Location"},
    {"statCd": "X4",  "reason" : "Arrived at Terminal Location"},
    {"statCd": "X5",  "reason" : "Arrived at Delivery Location Loading Dock"},
    {"statCd": "X6",  "reason" : "En Route to Delivery Location"},
    {"statCd": "X8",  "reason" : "Arrived at Pick-up Location Loading Dock"},
    {"statCd": "XB",  "reason" : "Shipment Acknowledged"},
    {"statCd": "AA",  "reason" : "Pick-up Appointment Date and/or Time"},
    {"statCd": "AB",  "reason" : "Delivery Appointment Date and/or Time"},
    {"statCd": "AC",  "reason" : "Estimated Delivery Appointment Date and/or Time NEW"},
    {"statCd": "ED",  "reason" : "Deliver No Earlier Than Date and/or Time"},
    {"statCd": "EP",  "reason" : "Pick-up No Earlier Than Date and/or Time"},
    {"statCd": "LD",  "reason" : "Deliver No Later Than Date and/or Time"},
    {"statCd": "LP",  "reason" : "Pick-up No Later Than Date and/or Time"},
    {"statCd": "X9",  "reason" : "Delivery Appointment Secured on This Date and/or Time"},
    {"statCd": "XA",  "reason" : "Pick-up Appointment Secured on This Date and/or Time"},
    {"statCd": "00",  "reason" : "Original"},
    {"statCd": "01",  "reason" : "Cancellation"},
    {"statCd": "02",  "reason" : "Add"},
    {"statCd": "03",  "reason" : "Delete"},
    {"statCd": "04",  "reason" : "Change"},
    {"statCd": "05",  "reason" : "Replace"},
    {"statCd": "06",  "reason" : "Confirmation"},
    {"statCd": "07",  "reason" : "Duplicate"},
    {"statCd": "08",  "reason" : "Status"},
    {"statCd": "09",  "reason" : ""},
    {"statCd": "10",  "reason" : "Not Found"}
  ];
}
