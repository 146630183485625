import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Criteria, SimpleCriteria, AdvancedCriteria, BatchCriteria } from '../../model/criteria.model';

@Component({
  selector: 'simple-tab',
  templateUrl: './simple-tab.template.html',
  styleUrls: ['./search-criteria.component.css'],
})
export class SimpleTabComponent {

  @Input() criteria: Criteria;
  @Input() isWebMode: boolean;
  @Input() isInboundQuery: boolean;

  @Output() onRemoveReference: EventEmitter<any> = new EventEmitter();
  @Output() onAddReference: EventEmitter<void> = new EventEmitter();
  @Output() onOpenHelp: EventEmitter<any> = new EventEmitter();
  @Output() onSetDocumentType: EventEmitter<string> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
  }

  // Method to trigger removal of a reference number
  removeReferenceNumber(referenceNumber: any) {
    this.onRemoveReference.emit(referenceNumber);
  }

  // Method to trigger addition of a reference number
  addReferenceNumber() {
    this.onAddReference.emit();
  }

  openHelp(help: any) {
    this.onOpenHelp.emit(help);
  }

  setDocumentType(event: string) {
    this.onSetDocumentType.emit(event);
  }

  search(search: any) {
    this.onSearch.emit(search);
  }

  clear() {
    this.onClear.emit();
  }



}
