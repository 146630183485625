import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringUtilsService {
  private NUMBERS = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

  constructor() {}

  /**
   * Replaces all the keys within the object with their values in the
   * string. The keys within the string must be surrounded with two curly
   * brackets, without any space.
   *
   * For example, if you had a key ABC in the replacements object, the
   * string would need to contain "{{ABC}}" (no quotes) to replace the
   * value.
   *
   * @param {string} str The input string to perform replacements on.
   * @param {object} replacements An object containing key-value pairs for replacements.
   * @return {string} The string with all occurrences of keys replaced with their corresponding values.
   * @throws {string} If str is not a string or if replacements is not an object or null.
   */
  replaceAll(str: string, replacements: { [key: string]: string }): string {
    if (typeof str !== 'string') {
      throw 'The str argument must be a string, got ' + typeof str + '.';
    } else if (typeof replacements !== 'object' || replacements === null) {
      throw 'The replacements argument must be an object and not null, got ' + (replacements === null ? 'null' : typeof replacements) + '.';
    }

    const keys = Object.keys(replacements);
    if (keys.length === 0) {
      return str;
    }

    for (const key of keys) {
      const value = replacements[key];
      str = str.replace('{{' + key + '}}', value);
    }

    return str;
  }

  /**
   * Returns the value of the provided argument as a string.
   * @param val The value to convert to string.
   * @returns {string} The value converted to string. If the val is null or undefined, an empty string is returned.
   */
  valueOf(val: any): string {
    if (typeof val === 'string') {
      return val;
    } else if (typeof val === 'number' || val instanceof Date || this.isBoolean(val)) {
      return val.toString();
    } else if (typeof val === 'undefined' || val === null) {
      return '';
    }

    return val.toString();
  }

  /**
   * Checks if the provided value is a boolean.
   * @param val The value to check.
   * @returns {boolean} Returns true if the value is a boolean, false otherwise.
   */
  isBoolean(val: any): boolean {
    return typeof val === 'boolean';
  }

  /**
   * Indicates whether the value is a blank string.
   * @param val The value to check.
   * @returns {boolean} Returns true if the value is a blank string, false otherwise.
   */
  isBlank(val: any): boolean {
    return this.trimToEmpty(val).length === 0;
  }

  /**
   * Indicates whether the value is not a blank string.
   * @param val The value to check.
   * @returns {boolean} Returns true if the value is not a blank string, false otherwise.
   */
  isNotBlank(val: any): boolean {
    return !this.isBlank(val);
  }

  /**
   * Trims the provided value to an empty string if it's null or undefined.
   * @param val The value to trim.
   * @returns {string} Returns the trimmed value as a string.
   */
  trimToEmpty(val: any): string {
    return this.valueOf(val).trim();
  }

  /**
   * Checks if the provided value is numeric.
   * @param val The value to check.
   * @returns {boolean} Returns true if the value is numeric, false otherwise.
   */
  isNumeric(val: any): boolean {
    let value = this.valueOf(val);

    if (value.trim().length === 0) {
      return false;
    }

    // You can have a + or -.
    if (value.charAt(0) === '-' || value.charAt(0) === '+') {
      value = value.substring(1);
    }

    let decimal = false;
    for (let index = 0; index < value.length; index++) {
      const chr = value.charAt(index);

      // You can have one decimal, of course...
      if (chr === '.' && !decimal) {
        decimal = true;
      }
      // But if you have more than one, it's not a number.
      else if (chr === '.') {
        return false;
      }
      // If it isn't a number, this isn't a number.
      else if (!this.NUMBERS.has(chr)) {
        return false;
      }
    }

    // Make sure there were characters we checked.
    return value.length > 0;
  }
}
