import { Injectable } from '@angular/core';

// Define interfaces to represent the data structures used
interface Segment {
  segmentId: string;
  [key: string]: any;
}

interface Result {
  index: number;
  type: string;
  id: string;
  source: {
    segments: Segment[];
    isa: {
      receiverId: string;
      timestampInMillis: number;
      senderId?: string;
      usageInd: string;
    };
    matches: {
      potentialCount: number;
      acceptedCount: number;
      mostRecentDiff?: string;
      mostRecentDiffKey?: string;
      autoacceptedCount?: number;
      inbound?: {
        tibcoLogs?: { prodLogs: any[]; testLogs: any[] };
        cfgHldErrors?: { prodErrors: any[]; testErrors: any[] };
        bol?: { prod?: any; test?: any };
        pur?: { prod?: any; test?: any };
        loadTender?: { prodMatches: any[]; testMatches: any[] };
      };
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {

  // Public methods
  getResults(results: Result[], resultType: string | null): any[] {
    resultType = typeof resultType === 'string' ? resultType.trim().toLowerCase() : null;
    const list = results.map((result, index) => this.getResult(index, result, resultType));
    return list;
  }

  getResult(index: number, result: Result, resultType: string | null): any {
    const tranTyp = this.getSegmentValues(result.source.segments, 'ST', 0);

    if (tranTyp === '204' || tranTyp === '211') {
      return this.getInboundResult(index, result, tranTyp);
    }

    return {
      meta: {
        index: result.index,
        type: result.type,
        id: result.id
      },
      isaId: result.source.isa.receiverId,
      gsId: this.getSegmentValues(result.source.segments, 'GS', 2),
      isaTimestamp: new Date(result.source.isa.timestampInMillis),
      events: this.getEvents(result.source.segments),
      proNbrs: this.getProNbrs(tranTyp, result.source.segments),
      proNumber: this.getProNbrs(tranTyp, result.source.segments),
      usageInd: result.source.isa.usageInd,
      potentialMatchCount: result.source.matches.potentialCount,
      acceptedCount: result.source.matches.acceptedCount,
      mostRecentDiffKey: result.source.matches.mostRecentDiffKey ?? '',
      autoacceptedCount: result.source.matches.autoacceptedCount ?? 0,
      mostRecentDiff: typeof result.source.matches.mostRecentDiff === 'string' ? result.source.matches.mostRecentDiff.trim() : "",
      resultIndex: index,
      isInbound: false,
      tranTyp: tranTyp
    };
  }

  private getProNbrs(tranTyp: string, segments: Segment[]): string {
    if (tranTyp === '210') {
      return this.getSegmentValues(segments, 'B3', 1);
    } else if (tranTyp === '214') {
      let proNbrs = this.getSegmentValues(segments, 'B10', 0);
      if (proNbrs.trim().length === 0) {
        proNbrs = this.getSegmentValues(segments, 'B10', 1);
      }
      return proNbrs;
    }
    return '';
  }

  private getEvents(segments: Segment[]): string {
    const events = this.getSegmentValues(segments, 'AT7', 0).trim();
    const events2 = this.getSegmentValues(segments, 'AT7', 2).trim();
    if (events.length > 0 && events2.length > 0) {
      return `${events}, ${events2}`;
    } else if (events.length > 0) {
      return events;
    } else if (events2.length > 0) {
      return events2;
    }
    return this.getSegmentValues(segments, 'Q5', 0);
  }

  private getInboundResult(index: number, result: Result, tranTyp: string): any {
    const matches = result.source.matches.inbound;
    const tibcoLogs = matches?.tibcoLogs;
    const cfgHldErrors = matches?.cfgHldErrors;
    const ediSenderShpIds = tranTyp === '204'
      ? this.getSegmentValues(result.source.segments, 'B2', 3)
      : this.getSegmentValues(result.source.segments, 'BOL', 2);

    return {
      meta: {
        index: result.index,
        type: result.type,
        id: result.id
      },
      isaId: result.source.isa.senderId,
      gsId: this.getSegmentValues(result.source.segments, 'GS', 1),
      isaTimestamp: new Date(result.source.isa.timestampInMillis),
      events: this.getEvents(result.source.segments),
      shipmentId: ediSenderShpIds,
      usageInd: result.source.isa.usageInd,
      prodBolCount: matches ? this.getInboundMatchCount(matches.bol?.prod) : 0,
      testBolCount: matches ? this.getInboundMatchCount(matches.bol?.test) : 0,
      prodPurCount: matches ? this.getInboundMatchCount(matches.pur?.prod) : 0,
      testPurCount: matches ? this.getInboundMatchCount(matches.pur?.test) : 0,
      prod990Count: matches?.loadTender?.prodMatches.length ?? 0,
      test990Count: matches?.loadTender?.testMatches.length ?? 0,
      prodBwLogs: tibcoLogs?.prodLogs.length ?? 0,
      testBwLogs: tibcoLogs?.testLogs.length ?? 0,
      prodCfgErrors: cfgHldErrors?.prodErrors.length ?? 0,
      testCfgErrors: cfgHldErrors?.testErrors.length ?? 0,
      acceptedCount: result.source.matches.acceptedCount,
      autoacceptedCount: result.source.matches.autoacceptedCount ?? 0,
      resultIndex: index,
      isInbound: true,
      tranTyp: tranTyp
    };
  }

  private getInboundMatchCount(match: any): number {
    return match && Object.keys(match).length > 0 ? 1 : 0;
  }

  private getSegmentValues(segments: Segment[], segmentId: string, elementIndex: number): string {
    segmentId = segmentId.trim().toUpperCase();
    const matches: string[] = [];
    segments.forEach(segment => {
      if (segmentId !== segment.segmentId.trim().toUpperCase()) {
        return;
      }
      const value = segment[`element_${elementIndex}`];
      if (value !== undefined && (typeof value !== 'string' || value.length > 0)) {
        matches.push(value);
      }
    });
    return matches.join(', ');
  }
}
