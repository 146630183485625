import { Injectable } from '@angular/core';
import { ClientUtilsService } from './clientutils.service'; // adjust the path as needed
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetUtilsService {
  constructor(private clientUtils: ClientUtilsService) {}

  /**
   * Sends a request for a single document.
   */
  get(index: string, type: string, id: string): Observable<any> {
    return new Observable((observer) => {
      this.clientUtils.getClient().get({
        index: index,
        type: type,
        id: id
      }, (error: any, response: any) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(response);
        }
        observer.complete();
      });
    }).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  /**
   * Sends a request for multiple documents.
   */
  mget(requestId: string, documents: any[], webContents: any): void {
    this.clientUtils.getClient().mget({
      body: {
        docs: this.getDocs(documents)
      }
    }, (error: any, response: any) => {
      const responseMessage = {
        id: requestId,
        response: response,
        error: error
      };

      if (typeof webContents === 'function') {
        webContents(responseMessage);
      } else {
        webContents.send('elastic-mget-response', responseMessage);
      }
    });
  }

  private getDocs(documents: any[]): any[] {
    return documents.map(document => ({
      _index: document.index,
      _type: document.type,
      _id: document.id
    }));
  }
}
