import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryUtilsService {

  // List of special characters that need to be escaped
  private SPECIAL_CHARACTERS: string[] = [
    "\\", "+", "-", "=", "&&", "||", ">", "<", "!", "(", ")", "{", "}", "[", "]", "^",
    "\"", "~", "*", "?", ":", "/", "AND", "OR", "NOT"
  ];

  constructor() { }

  /**
   * Escapes the special characters within the string.
   *
   * @param str The string to escape.
   * @param ignoreWildcard If true, then the asterisk (*) will not be escaped.
   * @return The escaped string.
   */
  escape(str: string | null | undefined, ignoreWildcard: boolean = false): string {
    // Handle null and undefined
    if (str === null || str === undefined) {
      return '';
    }

    // Ensure the input is a string
    str = this.ensureString(str);

    // Escape each special character except * if ignoreWildcard is true
    for (const char of this.SPECIAL_CHARACTERS) {
      if (char === '*' && ignoreWildcard) {
        continue;
      }
      // Replace each instance of the special character with its escaped form
      const regex = new RegExp(`\\${char}`, 'g');
      str = str.replace(regex, `\\${char}`);
    }

    return str;
  }
  ensureString(str: any): string {
    if (typeof str !== 'string') {
      // Ensure str is not null or undefined
      if (str === null || str === undefined) {
        throw new Error('Value cannot be converted to a string');
      }
      str = String(str); // Use String() instead of toString() for safety
    }
    return str;
  }
}
