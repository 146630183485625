<!-- Dialog container -->
<div style="display: none;">
  <div class="md-dialog-container" id="search-criteria-simple-help">
    <mat-dialog-content style="max-width: 700px;">
      <button mat-icon-button (click)="closeHelp()">
        <mat-icon>close</mat-icon>
      </button>
      <div *ngIf="showHelpType === 'reference'"></div>
      <div *ngIf="showHelpType === 'reports'"></div>
      <div *ngIf="showHelpType === 'parties'"></div>
    </mat-dialog-content>
  </div>
</div>

<!-- Form -->
<form (ngSubmit)="handleSubmit($event)">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)">
    <!-- Simple Search Tab -->
    <mat-tab label="basic">
        <simple-tab
          [criteria]="criteria"
          [isWebMode]="isWebMode"
          [isInboundQuery]="isInboundQuery()"
          (onRemoveReference)="removeReferenceNumber($event)"
          (onAddReference)="addReferenceNumber()"
          (onSetDocumentType)="setDocumentType($event)">
        </simple-tab>
      <div class="float-right">
        <!-- Clear Button -->
        <button mat-button color="primary" type="button" (click)="clear()" aria-label="clear search criteria" title="Clear search criteria">
          <mat-icon>clear</mat-icon>
          Clear
        </button>

        <!-- Search Button -->
        <button mat-raised-button color="primary" type="submit" (click)="criteria.simple.exportable=false; search('simple')" *ngIf="!criteria.simple.showExportOptions" aria-label="search" title="Search">
          <mat-icon>search</mat-icon>
          Search
        </button>

        <!-- Export Button (commented out) -->

        <button mat-raised-button color="primary" type="submit" (click)="criteria.simple.exportable=true; search('simple')" aria-label="export" title="Export">
          <mat-icon>save</mat-icon>
          Export
        </button>

      </div>

    </mat-tab>
    <!-- Advanced Search Tab -->
    <mat-tab label="advanced">
      <div class="row">
        <!-- Direction Select -->
        <div class="col m1 s4">
          <mat-form-field>
            <mat-select [(ngModel)]="criteria.advanced.gsDirection" name="gsDirection">
              <mat-option value="Default" selected>Default</mat-option>
              <mat-option value="Sender">Sender</mat-option>
              <mat-option value="Receiver">Receiver</mat-option>
            </mat-select>
            <mat-label>Direction</mat-label>
          </mat-form-field>
        </div>

        <!-- GS ID Input -->
        <div class="col m2 s8">
          <mat-form-field>
            <input matInput [(ngModel)]="criteria.advanced.gsId" name="gsId" aria-label="GS ID">
            <mat-label>GS ID</mat-label>
          </mat-form-field>
        </div>

        <!-- Transaction Type Input -->
        <div class="col m2 s12">
          <mat-form-field>
            <input matInput [(ngModel)]="criteria.advanced.tranTypCd" name="tranTypCd" aria-label="Transaction Type">
            <mat-label>Transaction Set (214, 210, etc.)</mat-label>
          </mat-form-field>
        </div>

        <!-- Segment Search Input -->
        <div class="col m7 s12" style="position: relative;">
          <mat-form-field>
            <input matInput [(ngModel)]="criteria.advanced.query" name="query" aria-label="Search Query">
            <mat-label>Segment search</mat-label>
          </mat-form-field>
          <button mat-icon-button (click)="openHelp('segmentsearch')" style="position: absolute; top: 20px; right: 10px;">
            <mat-icon>help_outline</mat-icon>
          </button>
        </div>
      </div>

      <div class="col">
        <!-- Time Range Selection -->
        <mat-form-field>
          <mat-label>Time Range</mat-label>
          <mat-select [(ngModel)]="criteria.advanced.timeRange" name="timeRange" aria-label="time range filter">
            <mat-option value="disabled">
              Limit the results to a time range
            </mat-option>
            <mat-option value="43200">Last 12 hours</mat-option>
            <mat-option value="86400">Last 24 hours</mat-option>
            <mat-option value="172800">Last 48 hours</mat-option>
            <mat-option value="604800">Last 7 days</mat-option>
            <mat-option value="2592000">Last 30 days</mat-option>
            <mat-option value="7776000">Last 90 days</mat-option>
            <mat-option value="15552000">Last 180 days</mat-option>
            <mat-option value="31536000">Last 365 days</mat-option>
            <mat-option value="0">Forever</mat-option>
            <mat-option value="custom">Custom date range...</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Custom Date Range -->
        <div *ngIf="criteria.advanced.timeRange === 'custom'">
          <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startPicker" [(ngModel)]="criteria.advanced.startTimeRange"
                   [max]="criteria.advanced.endTimeRange" required>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endPicker" [(ngModel)]="criteria.advanced.endTimeRange"
                   [min]="criteria.advanced.startTimeRange" [max]="criteria.advanced.maxEndTime" required>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>

          <button mat-icon-button color="primary" (click)="criteria.advanced.timeRange.setValue('86400')" title="Switch back to time range options">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>

      <mat-form-field>
        <mat-select [(ngModel)]="criteria.advanced.orderBy" name="orderBy" aria-label="sort">
          <mat-option value="true">
            Sort order
          </mat-option>
          <mat-option value="isa.timestamp:desc">
            ISA timestamp (Newest to Oldest)
          </mat-option>
          <mat-option value="isa.timestamp:asc">
            ISA timestamp (Oldest to Newest)
          </mat-option>
        </mat-select>
      </mat-form-field>




      <!-- Button Actions -->
      <div class="floatRight">
        <button mat-raised-button color="primary" type="button" (click)="clear()">Clear</button>
        <button mat-raised-button color="primary" type="submit" (click)="search('advanced')">
          Search</button>
      </div>
    </mat-tab>

    <!-- Reports Tab -->
    <mat-tab label="reports">
      <segment-report>
        (openHelp)="openHelp($event)"
        (post)="post"
      </segment-report>
    </mat-tab>
  </mat-tab-group>
</form>
