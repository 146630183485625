import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../util/title.service';
import { SearchResultQueueService } from '../services/searchresultqueue.service';
import { SearchResultService } from '../services/searchresult.service';
import { WebSearchService } from '../services/websearch.service';
import { SearchCriteriaService } from '../services/searchcriteria.service';
import { MessageService } from '../services/message.service';
import { ProgressService } from '../util/progress.service';
import { JsonUtilsService } from '../services/jsonutils.service';
import { LogService } from '../services/log.service';
import { SegmentService } from '../services/segment.service';
import { EdiPreviewService } from '../services/edipreview.service';
import { ReferenceNumber } from '../model/referencenumbers.model';
import { Party } from '../model/result.model';
import { EdiPreviewComponent } from '../edipreview/edi-preview.component';
import { MatDialog } from '@angular/material/dialog';



interface Result {
  tranTyp: string;
  isaTimestamp: Date;
  usageInd: string;
  isa: { senderId: string; receiverId: string };
  gs: { senderId: string; receiverId: string };
  parties: Party[];
  proNumber?: string;
  shipmentId?: string;
  referenceNumbers: ReferenceNumber [] // Assuming referenceNumbers is an object with key-value pairs
  statusTyp: string;
}

@Component({
  selector: 'app-web-results',
  templateUrl: './web-results.component.html'
  //styleUrls: ['./web-results.component.scss']
})
export class WebResultsComponent {

  @Output() onDownload: EventEmitter<any> = new EventEmitter();
  @Input() results: any[] = [];
  @Input() isInvoiceResults: boolean = false;
  @Input() isStatusResults: boolean = false;
  @Input() isInboundResults: boolean = false;
  statCdReason: string = '';

  constructor(
    private ediPreviewService: EdiPreviewService,
    private dialog: MatDialog){}

  download(result: any): void {
    this.onDownload.emit(result);
  }

  getStatusCdToolTip(statusType: string): void {
    // Implement logic to get status code tooltip
    this.statCdReason = `Reason for ${statusType}`;
  }
  ngOnInit() {
  }

  getFormattedReferenceNumbers(referenceNumbers: { [key: string]: string[] }): { key: string, values: string }[] {
    return Object.entries(referenceNumbers).map(([key, values]) => ({
      key,
      values: values.join(', ')
    }));
  }


  preview(result: any) {
    this.ediPreviewService.preview(result);  // Correct way to call the method

  }

}
