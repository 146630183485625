import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Injectable,
} from '@angular/core';
import { EdiUtilsNodeService } from '../util/ediutilsnode.service'; // Make sure to provide the right path for your service


@Component({
  selector: 'highlight-edi',
  templateUrl: './highlight-edi.component.html',
  styleUrls: ['./highlight-edi.component.css']
})
export class HighlightEdiComponent implements OnChanges, AfterViewInit {
  @Input() data: any;
  @Input() baseData: any;
  @Input() documentId: any;
  @Input() selected: { [key: string]: boolean } = {};
  @Output() hoverEvent = new EventEmitter<any>();

  segments: any[] = [];
  selectionEnabled = false;
  isSelectAll = false;
  id: string | null = null;

  constructor(private ediUtils: EdiUtilsNodeService, private elRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.updateSegments();
      this.id = this.getUniqueId(this.segments);
      this.bindHoverEvent();
    }

    if (changes['selected']) {
      this.selectionEnabled = !!this.selected;
    }
  }

  ngAfterViewInit(): void {
    this.setMinWidth();
  }

  selectAll(): void {
    if (!this.selectionEnabled) {
      return;
    }

    const lineNumbers = this.getLineNumbers();
    lineNumbers.forEach(lineNumber => {
      this.selected[lineNumber] = !this.selected[lineNumber];
    });
  }

  getLineNumbers(): number[] {
    if (!Array.isArray(this.segments)) {
      return [];
    }

    return this.segments.map((_, index) => index);
  }

  getUniqueId(segments: any[]): string {
    return segments.slice(0, 3).map(segment => segment.line).join('|');
  }

  bindHoverEvent(): void {
    const el = this.elRef.nativeElement.querySelectorAll('p.segment-row');
    el.forEach((element: HTMLElement) => {
      element.removeEventListener('mouseenter', this.hoverEventHandler.bind(this));
      element.removeEventListener('mouseleave', this.hoverEventHandler.bind(this));
      element.addEventListener('mouseenter', this.hoverEventHandler.bind(this));
      element.addEventListener('mouseleave', this.hoverEventHandler.bind(this));
    });
  }

  hoverEventHandler(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;
    const segmentId = element.getAttribute('data-segment-id');
    const elements: { [key: string]: string } = {};
    element.querySelectorAll('.segment-data .element').forEach((el, index) => {
      const elementNo = el.getAttribute('data-element-no');
      elements[elementNo!] = el.textContent || '';
    });

    this.hoverEvent.emit({
      hoverIn: event.type === 'mouseenter',
      segmentId,
      elements,
      id: this.id,
      documentId: this.documentId
    });
  }

  setMinWidth(): void {
    const el = this.elRef.nativeElement;
    const paragraphs = el.querySelectorAll('div.seg-container p');
    let maxWidth = 0;

    paragraphs.forEach((p: HTMLElement) => {
      const line = p.querySelector('span.line-no') as HTMLElement;
      const data = p.querySelector('span.segment-data') as HTMLElement;
      const width = line?.offsetWidth + data?.offsetWidth;
      maxWidth = Math.max(maxWidth, width || 0);
    });

    paragraphs.forEach((p: HTMLElement) => {
      p.style.minWidth = `${maxWidth + 15}px`;
    });
  }

  updateSegments(): void {
    this.segments = this.ediUtils.getSegments(this.data);
  }

  segmentTracker(index: number, segment: any): string {
    return `${index}-${segment.segmentId}`;
  }

  /**
   * Unique tracker for elements
   */
  elementTracker(index: number, element: any): number {
    return index; // Return the index as the unique identifier for tracking
  }
}
