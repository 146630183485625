<mat-card class="md-padding" >
  <table mat-table class="highlight web-results">
    <thead mat-header-row>
    <tr mat-row>
      <th mat-header-cell class="textCenter">Set</th>
      <th mat-header-cell class="textCenter">ISA Date/Time</th>
      <th mat-header-cell class="textCenter" matTooltip="Prod/Test">P/T</th>
      <th mat-header-cell>ISA (sender / receiver)<br/> GS (sender / receiver)</th>
      <th mat-header-cell>Parties</th>
      <th *ngIf="isInvoiceResults || isStatusResults" mat-header-cell>PRO</th>
      <th *ngIf="isInboundResults" mat-header-cell>Shipment ID</th>
      <th mat-header-cell>Reference Numbers</th>
      <th mat-header-cell class="textLeft">Status Type</th>
      <th mat-header-cell></th>
      <th mat-header-cell></th>
    </tr>
    </thead>

    <tbody mat-row>
    <tr mat-row *ngFor="let result of results" >
      <td mat-cell style="width: 40px;" class="textCenter">{{ result.tranTyp }}</td>
      <td mat-cell style="width: 155px;" class="textCenter">
        {{ result.isaTimestamp | date: 'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td mat-cell style="width: 40px; text-align: center;">{{ result.usageInd }}</td>
      <td mat-cell style="width: 190px;">
        <span style="margin-bottom: 3px; display: block;">{{ result.isa.senderId }} / {{ result.isa.receiverId }}</span>
        {{ result.gs.senderId }} / {{ result.gs.receiverId }}
      </td>
      <td mat-cell style="min-width: 400px;">
        <table style="width: 100%;" class="result-party-table">
          <tr *ngFor="let party of result.parties; let last = last">
            <td style="width: 30%; font-weight: bold; vertical-align: top; text-align: right; padding: 5px;"
                [ngStyle]="{'border-bottom': (!last ? '1px dotted #E0E0E0' : '')}">{{ party.type }}:</td>
            <td style="vertical-align: top; padding: 5px;"
                [ngStyle]="{'border-bottom': (!last ? '1px dotted #E0E0E0' : '')}">
              <div *ngIf="party.name1 || party.name2">
                  <span class="party-text-line">
                    <span>{{ party.name1 }}</span>
                    <span *ngIf="party.name1 && party.name2"> &ndash; </span>
                    <span>{{ party.name2 }}</span>
                  </span>
              </div>
              <div *ngIf="party.locId">
                <span class="party-text-line"><strong>Location ID:</strong> {{ party.locId }}</span>
              </div>
              <div *ngIf="party.address || party.cityStateText">
                  <span class="party-text-line">
                    <span>{{ party.address }}</span>
                    <span *ngIf="party.address && party.cityStateText"> &ndash; </span>
                    <span>{{ party.cityStateText }}</span>
                  </span>
              </div>
              <div matTooltip="{{ party.name1 }} - {{ party.name2 }}<br /><strong>Location ID:</strong> {{ party.locId }}">
              </div>
            </td>
          </tr>
        </table>
      </td>
      <td mat-cell *ngIf="isInvoiceResults || isStatusResults">
        {{ result.proNumber || '–' }}
      </td>
      <td mat-cell *ngIf="isInboundResults">
        {{ result.shipmentId || '–' }}
      </td>
      <td mat-cell style="max-width: 25%;">
        <table style="width: 100%;">
          <tr *ngFor="let ref of getFormattedReferenceNumbers(result.referenceNumbers)">
            <td style="width: 5%; font-weight: bold; vertical-align: top;">{{ ref.key }}:</td>
            <td>{{ ref.values }}</td>
          </tr>
        </table>
      </td>
      <td mat-cell>
        <div title="{{ statCdReason }}" (mouseover)="getStatusCdToolTip(result.statusTyp)">
          {{ result.statusTyp }}
        </div>
      </td>
      <td mat-cell style="min-width: 180px; padding: 1px;">
        <button mat-raised-button color="primary" (click)="preview(result)" title="View this result">
          <mat-icon>pageview</mat-icon> Open
        </button>
        <button mat-button (click)="download(result)" title="Download this result">
          <mat-icon>download</mat-icon>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</mat-card>
