import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import moment from 'moment';
import { SegmentQueryParserService} from './segmentqueryparser.service';
import { SearchUtilsService} from './searchutils.service';
import { LogService} from './log.service';

interface SearchRequest {
  index: string;
  size: number;
  from: number;
  body: {
    sort: string | { [key: string]: string };
    query: {
      bool: {
        must: Array<any>;
        must_not: Array<any>;
        filter: Array<any>;
        should?: {
          query_string: {
            query: string;
          };
        } | { bool: { should: Array<any>; minimum_should_match: number; boost: number } };
      };
    };
  };
}
@Injectable({
  providedIn: 'root'
})
export class SegmentSearchService {
  private readonly INDEX_IS_WEEKLY = true;

  constructor(
    private segmentQueryParser: SegmentQueryParserService,
    private searchUtils: SearchUtilsService,
    private logger: LogService
  ) {}


  search(request: any): Observable<any> {
    try {
      const searchRequest: SearchRequest = this.getRequest(request);
      this.logger.debug('Search request built', searchRequest);

// Convert the Promise returned by searchUtils.search() into an Observable
      return from(this.searchUtils.search(searchRequest)).pipe(
        catchError(e => {
          this.logger.error(e);
          return throwError(e);
        })
      );
    } catch (e) {
      this.logger.error(e);
      return throwError(e);
    }
  }


  private getRequest(request: any): SearchRequest {
    const query = this.segmentQueryParser.parse(request.query);
    this.logger.debug('Raw search request: ', request);
    const indexNames = this.getIndexPattern(request);
    this.logger.debug('Search index names', indexNames);

    const searchRequest: SearchRequest = {
      index: indexNames.join(','),
      size: this.isNumber(request.size) ? Math.min(request.size, 10000) : 200,
      from: this.isNumber(request.from) && request.from >= 0 ? request.from : 0,
      body: {
        sort: typeof request.orderBy === 'undefined' ? ['_score'] : this.getSort(request.orderBy),
        query: {
          bool: {
            must: this.getMust(query, request),
            must_not: this.getMustNot(query, request),
            filter: this.getFilters(request)
          }
        }
      }
    };
    console.log('segmentSearch.getRequest()', searchRequest);

    if (query.query.trim().length === 0) {
      return searchRequest;
    }

    searchRequest.body.query.bool.should = {
      query_string: {
        query: query.query.trim()
      }
    };

    return searchRequest;
  }

  /**
   * Returns an array of index names to search against.
   */
  private getIndexPattern(request: any): string[] {
    let timeRange = request.timeRange;
    if (typeof timeRange.relative !== 'undefined') {
      timeRange = timeRange.relative;
    }

    // It's a number.
    if (!isNaN(timeRange) && parseInt(timeRange.toString()) !== 0) {
      const currentTimeInMillis = new Date().getTime();
      const minTimeInMillis = timeRange <= 0 ? 0 : (currentTimeInMillis - (timeRange * 1000));

      timeRange = {
        start: minTimeInMillis,
        end: currentTimeInMillis
      };

      return this.getIndexNames(
        this.INDEX_IS_WEEKLY ? 'b2b_edi_data_{{DATE}}' : 'b2b_edi_{{DATE}}',
        new Date(minTimeInMillis),
        new Date(currentTimeInMillis)
      );
    } else if (!isNaN(timeRange)) {
      return this.INDEX_IS_WEEKLY ? ['b2b_edi_data_*'] : ['b2b_edi_2*'];
    }

    if (typeof timeRange.start === 'number') {
      timeRange.start = new Date(timeRange.start);
    }

    if (typeof timeRange.end === 'number') {
      timeRange.end = new Date(timeRange.end);
    }

    return this.getIndexNames(
      this.INDEX_IS_WEEKLY ? 'b2b_edi_data_{{DATE}}' : 'b2b_edi_{{DATE}}',
      timeRange.start,
      timeRange.end
    );
  }

  /**
   * Builds an array of index names from a date range with a pattern.
   */
  private getIndexNames(template: string, start: Date, end: Date): string[] {
    if (start > end) {
      throw new Error('The start date must be less than or equal to the end date.');
    }

    if (this.INDEX_IS_WEEKLY) {
      return this.getWeeklyIndexNames(template, moment(start).startOf('day'), moment(end).endOf('day'));
    }

    const indexes: string[] = [];
    let current = new Date(start.getTime());
    do {
      const year = this.leftPad(current.getFullYear(), 2, '0');
      const month = this.leftPad(current.getMonth() + 1, 2, '0');
      const day = this.leftPad(current.getDate(), 2, '0');

      indexes.push(template.replace('{{DATE}}', `${year}.${month}.${day}`));
      current.setTime(current.getTime() + (86400 * 1000));
    } while (current <= end);

    return indexes;
  }

  private getWeeklyIndexNames(template: string, start: moment.Moment, end: moment.Moment): string[] {
    const step = 86400 * 7;
    const indexNames: string[] = [];
    let current = moment(start);

    while (current <= end) {
      const date = current.format('YYYY.w');
      indexNames.push(template.replace('{{DATE}}', date));

      current.add(step, 'seconds');
    }

    return indexNames;
  }

  private leftPad(str: any, length: number, pad: string): string {
    str = str.toString();
    if (str.length >= length) {
      return str;
    }

    return `${this.repeatStr(pad, length - str.length)}${str}`;
  }

  private repeatStr(pad: string, length: number): string {
    let str = '';
    for (let index = 0; index < length; index++) {
      str += pad;
    }

    return str;
  }

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  private getFilters(request: any): any[] {
    const filters: any[] = [];
    // Add the time range filter.
    filters.push({
      range: {
        "isa.timestamp": this.getTimeRange(request.timeRange)
      }
    });


      this.logger.debug('The app is in browser mode, adding matchMode:nomatch filter.');
      filters.push({
        match: {
          'meta.matchMode': 'nomatch'
        }
      });


    // Filters for inbound.
    request.inbound = request.inbound || {};
    if (request.inbound.reqProdBolMatch) {
      filters.push(this.invertExists({
        exists: {
          field: 'matches.inbound.bol.prodInstId'
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqTestBolMatch) {
      filters.push(this.invertExists({
        exists: {
          field: 'matches.inbound.bol.testInstId'
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqProdPurMatch) {
      filters.push(this.invertExists({
        exists: {
          field: 'matches.inbound.pur.prodInstId'
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqTestPurMatch) {
      filters.push(this.invertExists({
        exists: {
          field: 'matches.inbound.pur.testInstId'
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqProdLoadTenderMatch) {
      filters.push(this.invertExists({
        range: {
          'matches.inbound.loadTender.prodMatches.length': {
            gte: 1
          }
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqTestLoadTenderMatch) {
      filters.push(this.invertExists({
        range: {
          'matches.inbound.loadTender.testMatches.length': {
            gte: 1
          }
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqTibcoBwLogs) {
      filters.push(this.invertExists({
        range: {
          'matches.inbound.tibcoLogs.totalLogs': {
            gte: 1
          }
        }
      }, request.inbound.invert));
    }

    if (request.inbound.reqCfgHldErrors) {
      filters.push(this.invertExists({
        range: {
          'matches.inbound.cfgHldErrors.totalErrors': {
            gte: 1
          }
        }
      }, request.inbound.invert));
    }

    return filters;
  }

  private invertExists(existsQuery: any, invert: boolean): any {
    if (!invert) {
      return existsQuery;
    }

    return {
      bool: {
        must_not: existsQuery
      }
    };
  }

  /**
   * Returns the must criteria for the query.
   */
  private getMust(query: any, request: any): any[] {
    const must = [{
      query_string: {
        query: (request.excludeAccepted ? "matches.potentialCount:1" : '1=1 ') + this.getUsageIndFilter(request.usageIndFilter)
      }
    }];

    for (const criteria of query.segments) {
      must.push(this.getNestedSegmentQuery(criteria));
    }

    return must;
  }

  /**
   * Returns the must not criteria for the query.
   */
  private getMustNot(query: any, request: any): any[] {
    const mustNot: any[] = [];

    const currentTimeInMillis = (new Date()).getTime();
    if (typeof request.selectedIgnoreFieldList !== 'undefined'
      && request.selectedIgnoreFieldList.length > 0
      && request.selectedIgnoreFieldList.length <= 9
      && ((request.timeRange.relative > 0
          && request.timeRange.relative <= 2592000)
        || (typeof request.timeRange.start !== 'undefined' && (request.timeRange.end - request.timeRange.start) <= 2592000))) {
      // Fail safe to make sure we do not use more than 9 elements, or durations over 30 days
      mustNot.push(this.getShoulds(request));
    }

    return mustNot;
  }

  private getShoulds(request: any): any {
    const getAllSubsets = (theArray: string[]) => theArray.reduce(
      (subsets, value) => subsets.concat(
        subsets.map(set => [value, ...set])
      ),
      [[]]
    );

    const fields = getAllSubsets(request.selectedIgnoreFieldList);

    const should = fields.map(field => ({
      match: {
        'matches.mostRecentDiffKey': field.sort().toString()
      }
    }));

    return {
      bool: {
        should,
        'minimum_should_match': 1,
        "boost": 1.0
      }
    };
  }

  /**
   * Returns a nested query for the segment.
   */
  private getNestedSegmentQuery(criteria: any): any {
    const elementMatch: any = {};
    elementMatch['segments.element_' + (criteria.elementIndex - 1)] = criteria.value.trim();
    const elementId = 'segments.element_' + (criteria.elementIndex - 1);
    const value = criteria.value.trim();

    return {
      nested: {
        path: "segments",
        query: {
          bool: {
            must: [
              {
                match: {
                  "segments.segmentId": criteria.segmentId.trim().toUpperCase()
                }
              },
              this.buildNestedValueQuery(value, elementId)
            ]
          }
        }
      }
    };
  }

  private buildNestedValueQuery(value: string, elementId: string): any {
    return {
      query_string: {
        query: value,
        analyze_wildcard: value.indexOf('*') >= 0,
        default_field: elementId,
        default_operator: 'AND'
      }
    };
  }

  /**
   * Returns the time range criteria.
   */
  private getTimeRange(timeRange: any): any {
    if (!isNaN(timeRange)) {
      const currentTimeInMillis = (new Date()).getTime();
      const minTimeInMillis = timeRange <= 0 ? 0 : (currentTimeInMillis - (timeRange * 1000));

      timeRange = {
        start: minTimeInMillis,
        end: currentTimeInMillis
      };
    }

    if (timeRange.start instanceof Date) {
      timeRange.start = timeRange.start.getTime();
    }

    if (timeRange.end instanceof Date) {
      timeRange.end = timeRange.end.getTime();
    }

    return {
      gte: timeRange.start,
      lte: timeRange.end,
      format: "epoch_millis"
    };
  }

  /**
   * Returns a filter to limit to a certain usage indicator value.
   */
  private getUsageIndFilter(value: string): string {
    if (value !== 't' && value !== 'p') {
      return '';
    }

    return ' AND isa.usageInd:' + value.trim().toUpperCase();
  }

  /**
   * Returns an object representing how results should be sorted.
   */
  private getSort(orderBy: string): any {
    if (orderBy.indexOf('_score') > -1) {
      return '_score';
    }

    const sort: any = {};
    if (orderBy.indexOf(':') === -1) {
      sort[orderBy] = 'desc';
    } else {
      const fieldName = orderBy.substring(0, orderBy.indexOf(':'));
      const dir = orderBy.substring(orderBy.indexOf(':') + 1);
      sort[fieldName] = dir;
    }

    return sort;
  }
}
