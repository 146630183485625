import { Injectable } from '@angular/core';
import { GetUtilsService } from '../util/getutils.service';
import { EdiUtilsNodeService } from '../util/ediutilsnode.service';
import { LogService } from './log.service';
import { SearchService } from './search.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompareviewService {

  constructor(
    private getUtils: GetUtilsService,
    private searchService: SearchService,
    private ediUtils: EdiUtilsNodeService,
    private logger: LogService
  ) {}

  // Public methods
  getAll(index: string, type: string, id: string): Observable<any> {
    const result: any = {};

    return new Observable(observer => {
      this.get(index, type, id).subscribe(document => {
        this.logger.info('Base document loaded:', document);
        result.document = document;
        result.documentIds = this.getDocumentMatchIds(document.source.matches);

        this.logger.info('Loading matches:', result.documentIds);
        this.searchService.search({
          type: 'documentIds',
          documentIds: result.documentIds,
          callback: (documents: any, error: any) => {
            if (error) {
              observer.error(error);
            } else {
              result.documents = documents;
              observer.next(result);
              observer.complete();
            }
          }
        });
      }, error => {
        observer.error(error);
      });
    });
  }

  get(index: string, type: string, id: string): Observable<any> {
    return this.getUtils.get(index, type, id).pipe(
      map(response => this.getResult(response)),
      catchError(error => of({ error }))
    );
  }

  formatDocument(document: any): any {
    const ediData = {
      data: document.source.meta.source,
      separators: document.source.meta.separators
    };

    const tranTypCd = this.ediUtils.getSegmentValues(ediData, 'ST', 0).join('');

    return {
      state: {},
      isaId: document.source.isa.receiverId,
      usageInd: document.source.isa.usageInd,
      tranTypCd: tranTypCd,
      gsIds: this.getGsIds(ediData, tranTypCd),
      proNumbers: this.getProNumbers(ediData, tranTypCd),
      refNumberLabel: this.getRefNumberLabel(ediData, tranTypCd),
      isaTimestamp: new Date(document.source.isa.timestampInMillis),
      document: document,
      edi: ediData,
      documentId: document.source.meta.documentId,
      fileName: document.source.meta.originalFileName,
      ediFormat: this.getFormatType(document.source.meta.ediFormatType)
    };
  }

  private getDocumentMatchIds(matches: any): string[] {
    const documentIds: any = {};
    this.addDocumentIds(documentIds, matches.potential);
    this.addDocumentIds(documentIds, matches.rejected);
    this.addDocumentIds(documentIds, matches.accepted);
    this.addDocumentIds(documentIds, matches.autoaccepted);

    return Object.keys(documentIds);
  }

  private addDocumentIds(documentIds: any, arr: any[]): void {
    if (!Array.isArray(arr)) return;

    arr.forEach(id => {
      documentIds[id] = true;
    });
  }

  private getResult(response: any): any {
    return {
      found: response.found,
      index: response._index,
      type: response._type,
      id: response._id,
      source: response._source,
      version: response._version
    };
  }

  private getRefNumberLabel(ediData: any, tranTypCd: string): string {
    return tranTypCd === '990' ? 'Shipment Identification Number' : 'PRO';
  }

  private getGsIds(ediData: any, tranTypCd: string): string {
    return this.ediUtils.getSegmentValues(ediData, 'GS', 2).join(', ');
  }

  private getProNumbers(ediData: any, tranTypCd: string): string[] {
    if (tranTypCd === '214') {
      const proNumbers = this.ediUtils.getSegmentValues(ediData, 'B10', 0);
      return proNumbers.length === 0 ? this.ediUtils.getSegmentValues(ediData, 'B10', 1) : proNumbers;
    } else if (tranTypCd === '210') {
      return this.ediUtils.getSegmentValues(ediData, 'B3', 1);
    } else if (tranTypCd === '990') {
      return this.ediUtils.getSegmentValues(ediData, 'B1', 1);
    }

    return [];
  }

  private getFormatType(type: string): string | undefined {
    if (!type) return undefined;

    switch (type.trim().toUpperCase()) {
      case 'STREAMING':
        return 'Streaming';
      case 'WORD_WRAPPED':
        return 'Word wrapped';
      case 'SEGMENT_TERMINATED':
        return 'Segment Terminated';
      default:
        return type;
    }
  }

  getDefaultQuery(document: any): string {
    const meta = document.document.source.meta;
    const data = {
      data: meta.source,
      separators: meta.separators
    };

    const tranTypCds = this.ediUtils.getSegmentValues(data, 'ST', 0);
    if (tranTypCds.length === 0) {
      return '';
    } else if (tranTypCds[0] === '214') {
      return this.get214DefaultQuery(data);
    } else if (tranTypCds[0] === '210') {
      return this.get210DefaultQuery(data);
    } else if (tranTypCds[0] === '990') {
      return this.get990DefaultQuery(data);
    }

    return '';
  }

  private get214DefaultQuery(data: any): string {
    const query: string[] = [];
    const gsIds = this.ediUtils.getSegmentValues(data, 'GS', 2);
    if (gsIds.length > 0) {
      query.push('GS:3:' + gsIds[0]);
    }

    const proNbrs = this.ediUtils.getSegmentValues(data, 'B10', 0);
    if (proNbrs.length > 0) {
      query.push('B10:1:' + proNbrs[0]);
    }

    if (proNbrs.length === 0) {
      query.push('B10:2:' + this.ediUtils.getSegmentValues(data, 'B10', 1).join(''));
    }

    const ediEventCodes = this.ediUtils.getSegmentValues(data, 'AT7', 0);
    if (ediEventCodes.length > 0) {
      query.push('AT7:1:' + ediEventCodes[0]);
    }

    const q5Codes = this.ediUtils.getSegmentValues(data, 'Q5', 0);
    if (q5Codes.length > 0) {
      query.push('Q5:1:' + q5Codes[0]);
    }

    return query.join(' ');
  }

  private get210DefaultQuery(data: any): string {
    const query: string[] = [];
    const gsIds = this.ediUtils.getSegmentValues(data, 'GS', 2);
    if (gsIds.length > 0) {
      query.push('GS:3:' + gsIds[0]);
    }

    const proNbrs = this.ediUtils.getSegmentValues(data, 'B3', 1);
    if (proNbrs.length > 0) {
      query.push('B3:2:' + proNbrs[0]);
    }

    return query.join(' ');
  }

  private get990DefaultQuery(data: any): string {
    const query: string[] = ['ST:1:990'];
    const gsIds = this.ediUtils.getSegmentValues(data, 'GS', 2);
    if (gsIds.length > 0) {
      query.push('GS:3:' + gsIds[0]);
    }

    const shipperNbrs = this.ediUtils.getSegmentValues(data, 'B1', 1);
    if (shipperNbrs.length > 0) {
      query.push('B1:2:' + shipperNbrs[0]);
    }

    return query.join(' ');
  }
}
