<mat-card class="md-padding">
  <app-search-criteria [criteria]="criteria"
                       (onSearch)="search($event)">
  </app-search-criteria>

  <!-- Display message if no results -->
  <div *ngIf="criteria.displayResults && results.length === 0">
    <p style="text-align: center; color: red;">
      There were no results for your search, try refining your time range or filters.
    </p>
  </div>

  <!-- Display results if present -->
  <div *ngIf="criteria.displayResults && results.length > 0" class="search-results">
    <div class="left">
      <mat-form-field>
        <mat-select [(ngModel)]="currentPage" (selectionChange)="search(true)" placeholder="View page">
          <mat-option *ngFor="let page of pages" [value]="page">{{ page }}</mat-option>
        </mat-select>
        <mat-label>View page</mat-label>
      </mat-form-field>
    </div>

    <div class="right">
      <p>
        Displaying page <strong>{{ currentPage }}</strong> of
        <strong>{{ totalResults | number: '1.0-0' }}</strong>
        (displaying <strong>{{ results.length | number: '1.0-0' }}</strong> results)
      </p>
    </div>

    <div class="break"></div>

    <!--<ng-container *ngTemplateOutlet="webResultsTemplate"></ng-container>-->

  </div>
</mat-card>
<app-web-results *ngIf="criteria.displayResults && results.length > 0" [results]="results" (onDownload)="onDownload($event)"></app-web-results>
<!-- Example of a potential component -->

