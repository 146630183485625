import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgressService } from '../util/progress.service';
import { MessageService } from './message.service';
import { SegmentSearchService } from './segmentsearch.service';
import { DocumentIdsSearchService } from './documentidsearch.service';
import { LogService } from './log.service';


interface Document {

  index?: any | null; // Optional property, can be null or a Date object
  type?: any | null;
  id?: any | null;
  version?: any | null;
  source?: any | null;
  documentId?: any | null;
}

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  private readonly localStorageKeyPrefix = 'search_';

  constructor(
    private progress: ProgressService,
    private messages: MessageService,
    private segmentSearch: SegmentSearchService,
    private documentIdsSearch: DocumentIdsSearchService,
    private logger: LogService
  ) {}

  search(request: any): Observable<any> {
  //  if (typeof request.callback !== 'function') {
  //    throw new Error('The request.callback must be a function.');
  //  }

    this.progress.showLoading();

    return new Observable(observer => {
      if (request.type === 'segmentsearch') {
        this.logger.debug('[segmentsearch] Issuing search request', request);
        this.segmentSearch.search(request).subscribe(
          response => this.handleResponse(observer, response, null, request),
          error => this.handleResponse(observer, null, error, request)
        );
      } else if (request.type === 'documentIds') {
        this.logger.debug('[documentids] Issuing search request', request);
        this.documentIdsSearch.search(request).subscribe(
          response => this.handleResponse(observer, response, null, request),
          error => this.handleResponse(observer, null, error, request)
        );
      } else {
        console.log("this is supposed to be an electron block of code, but we dont use it anymore. Why are we here?")
      }
    });
  }

  saveState(state: any): void {
    Object.keys(state).forEach(key => {
      if (state[key] !== undefined) {
        localStorage.setItem(this.localStorageKeyPrefix + key, JSON.stringify(state[key]));
      }
    });
  }

  getState(): any {
    const defaultState = {
      gsId: '',
      query: '',
      orderBy: 'isa.timestamp:desc',
      usageIndFilter: 't',
      timeRange: '86400',
      startTimeRange: new Date().getTime() - 24 * 60 * 60 * 1000, // Default to 1 day ago
      endTimeRange: new Date().getTime(),
      excludeAccepted: false,
      tranTypCd: '214',
      reqProdBolMatch: false,
      reqTestBolMatch: false,
      reqProdPurMatch: false,
      reqTestPurMatch: false,
      reqTestLoadTenderMatch: false,
      reqProdLoadTenderMatch: false,
      currentPage: 1,
      invertInboundReq: false,
      reqTibcoBwLogs: false,
      reqCfgHldErrors: false,
      selectedIgnoreFieldList: false
    };

    const state = { ...defaultState };

    Object.keys(defaultState).forEach(key => {
      const value = localStorage.getItem(this.localStorageKeyPrefix + key);
      if (value !== null) {
        state[key] = JSON.parse(value);
      }
    });

    return state;
  }

  private handleResponse(observer: any, response: any, error: any, request: any): void {
    this.progress.hideLoading();

    if (error) {
      this.messages.addMessage(error.displayName, error.message, 'error');
      return observer.error(error);
    }

    let results = this.getResults(request.type, response);

    // Add shard failures if necessary.
    if (response._shards?.failures?.length) {
      response._shards.failures.forEach((failure: any) => {
        this.messages.addMessage('Shard Failure', `${failure.index}: ${failure.reason.type} - ${failure.reason.reason}`, 'error');
      });
    }

    observer.next(results);
    observer.complete();
  }

  private getResults(requestType: string, response: any): any {
    if (!response) return undefined;

    if (requestType === 'documentIds') {
      return this.getDocumentIdsResults(response);
    } else {
      return this.getSearchResults(response);
    }
  }

  private getDocumentIdsResults(response: any): any {
    const documents: { [key: string]: Document } = {}
    response.hits.hits.forEach((hit: any) => {
      const document: Document = {
        index: hit._index,
        type: hit._type,
        id: hit._id,
        version: hit._version,
        source: hit._source,
      };

      const documentId = document.source?.meta?.documentId;
      document.documentId = documentId;
      if (documentId) {
        documents[documentId] = document;
      }
    });

    return documents;
  }

  private getSearchResults(response: any): any {
    const results: any = {
      total: response.hits.total,
      results: response.hits.hits.map((hit: any) => ({
        index: hit._index,
        type: hit._type,
        id: hit._id,
        score: hit._score,
        version: hit._version,
        source: hit._source
      }))
    };

    return results;
  }
}
