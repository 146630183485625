import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../util/title.service';
import { SearchCriteriaService } from '../services/searchcriteria.service';
import { SearchResultQueueService } from '../services/searchresultqueue.service';
import { SearchResultService } from '../services/searchresult.service';
import { WebSearchService } from '../services/websearch.service';
import { MessageService } from '../services/message.service';
import { ProgressService } from '../util/progress.service';
import { JsonUtilsService } from '../services/jsonutils.service';
import { LogService } from '../services/log.service';
import { SegmentService } from '../services/segment.service';
import { EdiPreviewService } from '../services/edipreview.service';
import { Criteria, SimpleCriteria, AdvancedCriteria, BatchCriteria } from '../model/criteria.model';

import moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
  //styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  // Constants
  private readonly RESULTS_PER_PAGE = 200;
  private readonly MAX_QUERY_SIZE = 7000;
  private readonly MAX_QUERY_ITERATIONS = 4;
  private readonly TARGET_RESULT_SIZE = 20000;

  // Public members
  results: any[] = [];
  totalResults = 0;
  isInboundResults = false;
  isStatusResults = false;
  isInvoiceResults = false;
  isInboundQuery = false;
  currentPage = 1;
  pages = [1];
  statCdReason = 'WAT';
  criteria: Criteria;

  constructor(
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private searchResultQueue: SearchResultQueueService,
    private searchResultService: SearchResultService,
    private webSearchService: WebSearchService,
    private searchCriteriaService: SearchCriteriaService,
    private messageService: MessageService,
    private progressService: ProgressService,
    private jsonUtilsService: JsonUtilsService,
    private logger: LogService,
    private segmentService: SegmentService,
    private ediPreviewService: EdiPreviewService
  ) {

  }

  ngOnInit(): void {
    this.titleService.setTitle('Search');

 //   if (window.appMode === 'web') {
 //     this.webRestore();
  //  }

    this.route.queryParams.subscribe(params => {
      if (params['restore'] === 'restore') {
        this.restore();
        setTimeout(() => this.search(false), 1000);
      }
    });

    this.criteria = {
      mode: 'simple',
      searchCallback: () => { /* implementation */ },
      simple: {
        orderBy: "isa.timestamp:desc",
        documentType: 'purbol',
        shipper: { name1: '', name2: '', address: '', city: '', state: '', postalCd: '', country: '', locId: '' },
        consignee: { name1: '', name2: '', address: '', city: '', state: '', postalCd: '', country: '', locId: '' },
        billTo: { name1: '', name2: '', address: '', city: '', state: '', postalCd: '', country: '', locId: '' },
        timeRange: '604800',
      },
      advanced: {},
      batch: {},
      displayResults: false,
      criteria: [],
      queries: [],
      size: 0,
      sort: "isa.timestamp:desc",
      start: null,
      end: null,
      timeRange: null,
      from: null,
      exportGTE: null
    };
  }

  isWebMode(): boolean {
    return true;
  }

  restore(): void {
    const criteria = this.searchCriteriaService.getPersisted();
    this.criteria.mode = criteria.mode;
    this.criteria.simple = criteria.simple;
    this.criteria.advanced = criteria.advanced;

    // we don't want to retrieve this from persistence
    this.criteria.simple.exportStartTime = null;
  }

  webRestore(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams['mode'] !== 'simple' && queryParams['mode'] !== 'advanced') {
      return;
    } else if (!this.jsonUtilsService.isJsonObject(queryParams['q'])) {
      return;
    }

    this.criteria.mode = queryParams['mode'];

    const transformer = (key, value) => {
      if (key === 'startTimeRange' || key === 'endTimeRange') {
        return moment(value).toDate();
      }
      return value;
    };

    if (queryParams['mode'] === 'simple') {
      this.criteria.simple = this.jsonUtilsService.parseJson(queryParams['q'], transformer);
      this.criteria.advanced = {};
    } else {
      this.criteria.advanced = this.jsonUtilsService.parseJson(queryParams['q'], transformer);
      this.criteria.simple = {} as SimpleCriteria;
    }

    this.criteria.simple.exportStartTime = null;

    setTimeout(() => {
      this.search(false);
    }, 1000);
  }


  search(refresh: boolean): void {
    if(this.criteria.simple.exportable) {
      //reset for next submit
      this.criteria.simple.exportStartTime = null;
      var results = [];
      let counter = this.MAX_QUERY_ITERATIONS;
      //Progress.show();
      this.queryForAllResults(counter, this.criteria, results);

    } else {
      if (!refresh) {
        this.criteria.displayResults = false;
        this.results = [];
        this.totalResults = 0;
        this.currentPage = 1;
      }

      // if (window.appMode === 'web') {
      const queryParams = this.criteria.mode === 'simple'
        ? { mode: 'simple', q: this.jsonUtilsService.toUrlJson(this.criteria.simple) }
        : { mode: 'advanced', q: this.jsonUtilsService.toUrlJson(this.criteria.advanced) };
      this.router.navigate([], { queryParams });
      // }
      this.searchCriteriaService.search(this.criteria, true).subscribe(
        (resp) => {
          console.log(resp);
          this.criteria.displayResults = true;
          this.totalResults = resp.total;
          this.results = this.webSearchService.getResults(resp.results, this.getResultType());
          this.pages = this.buildPagesArray(resp.total);
        },
        (error) => {
          this.handleError(error);
        }
      );
    }

  }

  queryForAllResults(counter: number, criteria: Criteria, results: any[]): void {

    if (results.length < this.TARGET_RESULT_SIZE && counter > 0) {
      this.searchCriteriaService.search(criteria, true).subscribe((resp: any) => {

        const resLen = results.length;
        results.push(...resp.results);

        // Remove duplicate entries
        const deduped = results.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

        if (resp.results.length < 10) {
          counter = 0;
        } else {
          counter -= 1;

          // Check if new results were gained
          if (resLen === deduped.length && resLen > 0) {
            counter = 0;
          } else {
            criteria.simple.exportStartTime = resp.results[resp.results.length - 1].source.isa.timestampInMillis;
          }
        }

        this.queryForAllResults(counter, criteria, deduped);

      },
        (error) => {
          this.handleError(error);
        });
    } else {
     // Progress.hide();
      const scrolledResult: any = {};
      const csv: any = { data: [] };
      csv.data[0] = [];

      const headers = this.getSegmentList(criteria.simple.documentType);
      for (let i = 0; i < headers.length; i++) {
        csv.data[0][i] = headers[i].name;
      }

      scrolledResult.results = results.sort((a, b) => b.source.isa.timestampInMillis - a.source.isa.timestampInMillis);

      this.addMoreResults(scrolledResult, headers, csv);

      const curDate = moment().format("YYYY-MM-DD");
      const filename = `export-${criteria.simple.documentType}-${curDate}.csv`;
      const csvData = this.arrayToCSV(csv.data);

      this.postDownload(filename, csvData);
      criteria.simple.exportable = false;
      criteria.simple.exportStartTime = null;
    }
  }

  getSegmentList(type) {
    if(type === 'purbol')
      return this.segmentService.getPurBolSegments();
    else if(type === 'status')
      return this.segmentService.getStatusSegments();
    else if(type === 'invoice')
      return this.segmentService.getInvoiceSegments();
    else return null;
  }

  addMoreResults (results, headers, csv) {
    //capture this now
    for(let i = 0; i < results.results.length; i++) {
      csv.data[i+1]=[];
      for(let j = 0; j < headers.length; j++) {
        if (headers[j].dependent_element != null) {
          var seg = this.conditionalSegmentLookup(headers[j], results.results[i].source);
          csv.data[i + 1][j] = seg;
        }  else if (headers[j].list != null) {
          var list = results.results[i].source.segments.filter(obj => obj.segmentId === headers[j].segment);
          if(list.length > 0) {
            this.insertAllSegments(list, headers, csv, j, i);
          }
        } else {
          this.addNormalSegment(results,headers,csv, j, i);
        }
      }
    }
  }

  conditionalSegmentLookup(segment, results) {
    var seg;
    if(segment.dependent_segment == undefined) {
      seg = this.getSegments(segment.segment, results);
      //   console.log(seg);

      var depEl: number | string = segment.dependent_element - 1;
      depEl = "element_" + depEl;
      for (let i = 0; i < seg.length; i++) {
        if(seg[i].segment[depEl] == segment.dependent_element_val) {
          var el: number | string = segment.element - 1;
          el = "element_" + el;
          return seg[i].segment[el];
        }
      }
    } else {
      seg = this.getSegments(segment.dependent_segment,results);

      for (let i = 0; i < seg.length; i++) {
        let dep_el: number | string = segment.dependent_element - 1;
        dep_el = "element_" + dep_el;
        if( seg[i].segment[dep_el] == segment.dependent_element_val) {
          var parentSeg = this.getParentSegment(segment, seg[i].index, results);
          var el: number | string = segment.element-1;
          el = "element_" + el;
          return parentSeg[el];
        }
      }
    }

    return "";
  }

  getSegments(segmentId, results) {
    let result = [];
    let index=0;
    for(let i =0; i < results.segments.length; i++) {
      if(segmentId == results.segments[i].segmentId) {
        result[index++] = { "segment" : results.segments[i],
          "index" : i}
      }
    }
    return result;
  }

  getParentSegment(segment, index, results) {
    var regex = /\d+/g;
    let segNum = segment.segment.match(regex);
    for(let i = index; i< index+ segNum[0]; i++) {
      if(results.segments[i] !== undefined && segment.segment == results.segments[i].segmentId ) {
        return results.segments[i];
      }
    }
    var result = {
      segmentId: String,
      elementName: String
    };
    result.segmentId = segment.segment;
    let elementName = "element_" + segment.element;
    return result;

  }

  arrayToCSV(convertable) {
    let csvString="";
    for(let i = 0; i < convertable.length; i++) {
      for(let j = 0; j < convertable[0].length; j++) {
        if( j != 0 ) {
          csvString = csvString + " ,";
        }

        if(convertable[i][j] !== undefined && convertable[i][j] !== null) {
          let reped = convertable[i][j].replace(/,/g, ';');
          csvString = csvString + reped;
        }
      }
      csvString = csvString + "\r\n";
    }
    return csvString;

  }

  insertAllSegments(list, headers, csv, j, i) {
    let refLength ;
    if(list.length > 5) {
      refLength=5;
    } else {
      refLength=list.length;
    }

    for(let y=0; y<refLength; y++){
      if(y>0) {
        csv.data[0][j+y]=headers[j].name+(y+1);
      }

      let conSeg='';
      let x=0;
      let nm = "element_" + x;
      while(list[y][nm] !== undefined) {
        if (x > 0) {
          conSeg += ';';
        }
        conSeg += list[y][nm];
        x++;
        nm = "element_" + x;
        if(x>10)
          break;
      }
      csv.data[i+1][j+y]=conSeg;
    }
  }

  addNormalSegment(results, headers, csv, j, i) {
    var seg = results.results[i].source.segments.find(obj => obj.segmentId === headers[j].segment);
    var nm: number | string = headers[j].element - 1;
    nm = "element_" + nm;
    if (seg != undefined) {
      if (seg[nm] != undefined) {
        csv.data[i + 1][j] = seg[nm];
      } else {
        csv.data[i + 1][j] = " ";
      }
    } else {
      csv.data[i + 1][j] = " ";
    }
  }

  getResultType(): string {
    return this.isInboundResults ? 'purbol' : this.isInvoiceResults ? 'invoice' : this.isStatusResults ? 'status' : '';
  }

  buildPagesArray(total: number): number[] {
    const totalPages = Math.ceil(total / this.RESULTS_PER_PAGE);
    const maxPages = Math.min(totalPages, 10);
    return Array.from({ length: maxPages }, (_, i) => i + 1);
  }

  handleError(error: any): void {
    this.criteria.displayResults = false;
    this.results = [];
    this.totalResults = 0;
    this.logger.error(error);
    this.messageService.addMessage('Error', error.message || error, 'err');
  }

  open(result: any): void {
    this.searchResultQueue.setResults(this.results, result.resultIndex);

    if (result.isInbound) {
      this.navigation.gotoInboundView(result.meta.index, result.meta.type, result.meta.id);
    } else {
      this.navigation.gotoCompareView(result.meta.index, result.meta.type, result.meta.id);
    }
  }

  openProView(result: any): void {
    const tranTypCd = this.criteria.mode === 'advanced'
      ? this.criteria.advanced.tranTypCd.trim()
      : this.criteria.simple.documentType === 'invoice' ? '210'
        : this.criteria.simple.documentType === 'status' ? '214' : '204';

    this.navigation.gotoProView(result.gsId.trim(), tranTypCd, result.proNbrs);
  }

  preview(result: any) {
    this.ediPreviewService.preview(result);  // Correct way to call the method
  }

  getStatusCdToolTip(statCd: string): string {
    const statCds = this.segmentService.getStatusCds();
    const statCdReason = statCds.find(cd => cd.statCd === statCd)?.reason || 'Unknown Stat Cd';
    this.statCdReason = statCdReason;
    return statCdReason;
  }

  onDownload(result: any): void {
    let name = result.meta.source.meta.originalFileName;

    if (!name || name.length === 0) {
      // No name? Use documentId instead
      name = result.meta.source.meta.documentId + '.txt';
    }

    const formatted = this.toText(result.meta.source.meta.separators, result.meta.source.meta.source);
    this.postDownload(name, formatted);
  }

  private toText(separators: any, source: string): string {
    const segments = source.split(separators.segment);
    for (let i = 0; i < segments.length; i++) {
      segments[i] = segments[i].trim();
    }
    return segments.join(separators.segment + '\n');
  }

  private postDownload(name: string, content: string): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    if (!name || name.length === 0) {
      a.download = 'download.txt';
    } else {
      a.download = name;
    }

    const encodedContent = encodeURIComponent(content);
    a.href = `data:text/html,${encodedContent}`;
    a.click();

    // Clean up
    document.body.removeChild(a);
  }

}
