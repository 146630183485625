import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SegmentQueryParserService {
  constructor() {}

  parse(query: string): { segments: any[]; query: string } {
    const WHITESPACE_REGEX = /\s+/;

    if (
      typeof query !== 'string' ||
      query.trim().length === 0 ||
      query.indexOf(':') < 0
    ) {
      return {
        segments: [],
        query:
          typeof query !== 'string' || query.trim().length === 0 ? '' : query
      };
    }

    const result: { segments: any[]; query: string } = {
      segments: [],
      query: ''
    };
    const chunks = query.split(':');
    if (chunks.length < 3) {
      result.query = query;
      return result;
    }

    const queryBuffer: string[] = [];
    for (let index = 0; index < chunks.length; index++) {
      const chunk = chunks[index];

      if (!this.isSegmentChunk(chunks, index)) {
        queryBuffer.push(chunk.trim());
        continue;
      }

      const firstChunk = chunks[index];
      const middleChunk = chunks[index + 1];
      const endChunk = chunks[index + 2];

      // Extract the segment ID.
      const firstChunks = firstChunk.split(/\s+/g);

      // If there is a string in there before the segment ID, put those in the
      // buffer.
      if (firstChunks.length > 0) {
        queryBuffer.push(
          firstChunks.slice(0, firstChunks.length - 1).join(' ')
        );
      }

      // Now get the segment ID.
      const segmentId = firstChunks[firstChunks.length - 1].trim().toUpperCase();

      // The element offset.
      const elementIndex = parseInt(middleChunk, 10);

      // Now extract the value we're looking for.
      const endChunks = endChunk.split(/\s+/g);

      // Then the value.
      const elementValue = endChunks[0];

      result.segments.push({
        segmentId: segmentId,
        elementIndex: elementIndex,
        value: elementValue
      });

      // If there was only one value, move past all of this.
      if (endChunks.length === 1) {
        index += 3;
        continue;
      }

      // Otherwise there is more to the end chunk we may need to consider.
      // In which case, update index + 2.
      chunks[index + 2] = endChunks.slice(1).join(' ');

      // Add one to the index, another increment will occur right after this
      // resulting in us going to index + 2 (which is what we want!).
      index += 1;
    }

    result.query = queryBuffer.join(' ').trim();

    return result;
  }

  private isSegmentChunk(chunks: string[], index: number): boolean {
    if (index + 2 >= chunks.length) {
      return false;
    }

    const firstChunk = chunks[index];
    const middleChunk = chunks[index + 1];
    const endChunk = chunks[index + 2];

    if (this.isWhitespace(firstChunk.substring(firstChunk.length - 1, firstChunk.length))) {
      return false;
    } else if (middleChunk.trim().length !== middleChunk.length || isNaN(parseInt(middleChunk, 10))) {
      return false;
    } else if (this.isWhitespace(endChunk.substring(0, 1))) {
      return false;
    }

    return true;
  }

  private isWhitespace(str: string): boolean {
    if (str === null) {
      return false;
    } else if (typeof str !== 'string') {
      str = String(str);
    }

    return /\s+/.test(str);
  }
}
