import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  // Internal state of the progress service
  private progressState = new BehaviorSubject<{ visible: boolean; noShadow: boolean; percent: number }>({
    visible: false,
    noShadow: false,
    percent: -1
  });

  // Observable to provide the current state to the component
  public progressState$ = this.progressState.asObservable();

  constructor() {}

  /**
   * Shows the loading bar, which can either be indeterminate or show a specific percent.
   * @param {number} percent The percent to set the progress bar to.
   * @param {boolean} withoutShadow Whether or not to show the shadow with the progress bar.
   */
  showLoading(percent?: number, withoutShadow?: boolean): void {
    const progress = {
      visible: true,
      noShadow: !!withoutShadow,
      percent: typeof percent === 'number' ? Math.max(0, Math.min(percent, 100)) : -1
    };
    this.progressState.next(progress);
  }

  /**
   * Hides the loading bar.
   */
  hideLoading(): void {
    const progress = {
      visible: false,
      noShadow: false,
      percent: -1
    };
    this.progressState.next(progress);
  }
}
