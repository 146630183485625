import { Injectable } from '@angular/core';
import { of,Observable,throwError } from 'rxjs';
import {  catchError, tap } from 'rxjs/operators'
import { SegmentSearch2Service } from './segmentsearch2.service'; // Update with actual path
import { StringUtilsService } from '../util/stringutils.service'; // Update with actual path
import { SearchService } from './search.service'; // Update with actual path
import { QueryUtilsService } from '../util/queryutils.service'; // Update with actual path
import moment from 'moment';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {

  private readonly RESULTS_PER_PAGE = 200;

  private readonly REFERENCE_NUMBER_LOCATIONS = [
    { segmentId: 'L11', type: 1, value: 0 },
    { segmentId: 'N9', type: 0, value: 1 },
    { segmentId: 'MAN', type: 0, value: 1 }
  ];

  private readonly IMPLIED_REF_NUMBER_LOCATIONS = {
    PRO: [
      { segmentId: 'B10', value: [0, 1] },
      { segmentId: 'B3', value: [1] }
    ],
    'SN#': [
      { segmentId: 'B10', value: [1] },
      { segmentId: 'B1', value: [1] },
      { segmentId: 'B3', value: [2] },
      { segmentId: 'B2', value: [3] },
      { segmentId: 'BOL', value: [2] }
    ],
    'BATCH#': [{ segmentId: 'AK1', value: [1] }],
    PO: [
      { segmentId: 'SPO', value: [0] },
      { segmentId: 'OID', value: [1] }
    ],
    'PO#': [
      { segmentId: 'SPO', value: [0] },
      { segmentId: 'OID', value: [1] }
    ]
  };

  private readonly SHIPPER_N101_QUALIFIERS = ['SE', 'SH', 'SF', 'PW', 'FW', 'VN', 'SU', 'SN'];
  private readonly CONSIGNEE_N101_QUALIFIERS = ['CN', 'UC', 'ST', 'OB', 'MA', 'BS', 'BY', 'SN', 'AG'];
  private readonly BILL_TO_N101_QUALIFIERS = ['BT', 'BS', 'BY', 'RE', 'PF', 'AG'];

  constructor(
    private segmentSearch: SegmentSearch2Service,
    private stringUtils: StringUtilsService,
    private searchService: SearchService,
    private queryUtils: QueryUtilsService
  ) {}

  search(criteria: any, save?: boolean): Observable<any> {
    if (typeof criteria !== 'object') {
      return throwError(new Error('The criteria must be an object.'));
    }

    if (save) {
      this.persist(criteria);
    }

    if (criteria.mode === 'simple') {
      return this.searchSimple(criteria, criteria.simple);
    } else if (criteria.mode === 'advanced') {
      return this.searchAdvanced(criteria, criteria.advanced);
    } else {
      return throwError(new Error('The criteria.mode must be simple or advanced.'));
    }
  }

  searchSimple(options: any, simple: any): Observable<any> {
    let criteria: Criteria = {
      mode: 'simple',
      searchCallback: () => { /* implementation */ },
      simple: simple,
      advanced: {},
      batch: {},
      displayResults: false,
      criteria: [],
      queries: [],
      size: simple.exportable ? simple.size : this.RESULTS_PER_PAGE,
      sort: this.buildSort(simple.orderBy),
      start: null,
      end: null,
      timeRange: this.buildTimeRange(simple.timeRange, simple.startTimeRange, simple.endTimeRange),
      from: (simple.exportable && simple.exportFrom !== undefined) ? simple.exportFrom : Math.max(options.page - 1, 0) * this.RESULTS_PER_PAGE,
      exportGTE: null

    };

    console.log(criteria);

    // Add ISA sender filter
    if (this.stringUtils.isNotBlank(simple.isaSenderId)) {
      criteria.criteria.push({
        path: 'segments',
        clause: 'must',
        joinType: 'and',
        criteria: [{
          fields: {
            segmentId: 'ISA',
            element_5: this.stringUtils.trimToEmpty(simple.isaSenderId)
          }
        }]
      });
    }

    // Add ISA receiver filter
    if (this.stringUtils.isNotBlank(simple.isaReceiverId)) {
      criteria.criteria.push({
        path: 'segments',
        clause: 'must',
        joinType: 'and',
        criteria: [{
          fields: {
            segmentId: 'ISA',
            element_7: this.stringUtils.trimToEmpty(simple.isaReceiverId)
          }
        }]
      });
    }

    // Add GS sender filter
    if (this.stringUtils.isNotBlank(simple.gsSenderId)) {
      criteria.criteria.push({
        path: 'segments',
        clause: 'must',
        joinType: 'and',
        criteria: [{
          fields: {
            segmentId: 'GS',
            element_1: this.stringUtils.trimToEmpty(simple.gsSenderId)
          }
        }]
      });
    }

    // Add GS receiver filter
    if (this.stringUtils.isNotBlank(simple.gsReceiverId)) {
      criteria.criteria.push({
        path: 'segments',
        clause: 'must',
        joinType: 'and',
        criteria: [{
          fields: {
            segmentId: 'GS',
            element_2: this.stringUtils.trimToEmpty(simple.gsReceiverId)
          }
        }]
      });
    }

    // Add document usage indicator filter
    if (this.stringUtils.isNotBlank(simple.usageInd) && (this.stringUtils.trimToEmpty(simple.usageInd) === 'p' ||
      this.stringUtils.trimToEmpty(simple.usageInd) === 't')) {
      criteria.queries.push({
        clause: 'filter',
        query: {
          match: {
            'isa.usageInd': this.stringUtils.trimToEmpty(simple.usageInd).toUpperCase()
          }
        }
      });
    }

    // Add document type filter
    if (this.stringUtils.isNotBlank(simple.documentType)) {
      let filter = this.buildDocumentTypeFilter(simple.documentType);
      if (filter !== null) {
        criteria.criteria.push(filter);
      }
    }


    // Add reference number filters
    if (Array.isArray(simple.referenceNumbers) && simple.referenceNumbers.length > 0) {
      for (let referenceNumber of simple.referenceNumbers) {
        let clause = this.buildReferenceNumberFilter(referenceNumber);
        if (clause !== null) {
          criteria.criteria.push(clause);
        }
      }
    }

    // Add party filters
    if (simple.shipper) {
      let filter = this.buildPartyFilter(simple.shipper, this.SHIPPER_N101_QUALIFIERS);
      if (filter !== null) {
        criteria.criteria.push(filter);
      }

      let locIdFilter = this.buildLocIdFilter(simple.shipper, this.SHIPPER_N101_QUALIFIERS);
      if (locIdFilter !== null) {
        criteria.criteria.push(locIdFilter);
      }
    }

    if (simple.consignee) {
      let filter = this.buildPartyFilter(simple.consignee, this.CONSIGNEE_N101_QUALIFIERS);
      if (filter !== null) {
        criteria.criteria.push(filter);
      }

      let locIdFilter = this.buildLocIdFilter(simple.consignee, this.CONSIGNEE_N101_QUALIFIERS);
      if (locIdFilter !== null) {
        criteria.criteria.push(locIdFilter);
      }
    }

    if (simple.billTo) {
      let filter = this.buildPartyFilter(simple.billTo, this.BILL_TO_N101_QUALIFIERS);
      if (filter !== null) {
        criteria.criteria.push(filter);
      }

      let locIdFilter = this.buildLocIdFilter(simple.billTo, this.BILL_TO_N101_QUALIFIERS);
      if (locIdFilter !== null) {
        criteria.criteria.push(locIdFilter);
      }
    }

    // Add export start time
    if (options.simple.exportStartTime !== undefined && options.simple.exportStartTime != null && options.simple.exportStartTime > 0) {
      criteria.exportGTE = options.simple.exportStartTime;
    }

    // Send the request using the segment search service
    return this.segmentSearch.search(criteria).pipe(
      // You can directly return the observable without modifying the response if no transformation is needed
      catchError(err => {
        // Handle errors and rethrow them using throwError
        console.error('Error occurred during search:', err);
        return throwError(() => new Error(err)); // Updated syntax for throwError
      }));
  }

  searchAdvanced(options: any, advanced: any): Observable<any> {
    // Build our query -- add the quick shortcut for GS ID.
    let query = '';

    if (advanced.gsId.trim().length > 0) {
      if (advanced.gsDirection.trim() === "Sender") {
        query += 'GS:2' + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      } else if (advanced.gsDirection.trim() === "Receiver") {
        query += 'GS:3' + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      } else {
        query += 'GS:' + ((advanced.tranTypCd === '204' || advanced.tranTypCd === '211') ? '2' : '3') + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      }
    }

    if (advanced.tranTypCd.trim().length > 0) {
      query += ' ST:1:' + advanced.tranTypCd.trim();
    }

    const isInboundQuery = advanced.tranTypCd.trim() === '204' || advanced.tranTypCd.trim() === '211';

    // Then add the rest.
    query += ' ' + advanced.query;

    return this.searchService.search({
      type: 'segmentsearch',
      query: query.trim(),
      orderBy: advanced.orderBy,
      usageIndFilter: advanced.usageIndFilter,
      excludeAccepted: advanced.excludeAccepted,
      timeRange: this.buildTimeRange(advanced.timeRange, advanced.startTimeRange, advanced.endTimeRange),
      tranTypCd: advanced.tranTypCd,
      size: this.RESULTS_PER_PAGE,
      from: Math.max(options.page - 1, 0) * this.RESULTS_PER_PAGE,
      inbound: {
        reqProdBolMatch: isInboundQuery ? advanced.reqProdBolMatch : false,
        reqTestBolMatch: isInboundQuery ? advanced.reqTestBolMatch : false,
        reqProdPurMatch: isInboundQuery ? advanced.reqProdPurMatch : false,
        reqTestPurMatch: isInboundQuery ? advanced.reqTestPurMatch : false,
        reqProdLoadTenderMatch: isInboundQuery ? advanced.reqProdLoadTenderMatch : false,
        reqTestLoadTenderMatch: isInboundQuery ? advanced.reqTestLoadTenderMatch : false,
        reqTibcoBwLogs: isInboundQuery ? advanced.reqTibcoBwLogs : false,
        reqCfgHldErrors: isInboundQuery ? advanced.reqCfgHldErrors : false,
        invert: isInboundQuery ? advanced.invertInboundReq : false
      },
      selectedIgnoreFieldList: advanced.selectedIgnoreFieldList
    }).pipe(
      tap(results => console.log('Search results:', results)),
      catchError(error => {
        console.error('Search error:', error);
        return of(error); // Return error as observable
      })
    );
  }



  persist(criteria: any): void {
    localStorage.setItem('criteriaMode', criteria.mode);
    localStorage.setItem('criteriaAdvanced', criteria.advanced);
    localStorage.setItem('criteriaSimple', criteria.simple);
  }

  getPersisted(): any {
    return {
      mode: localStorage.getItem('criteriaMode') || 'simple',
      simple: localStorage.getItem('criteriaSimple') || {},
      advanced: localStorage.getItem('criteriaAdvanced') || {}
    };
  }

  private buildLocIdFilter(party: any, qualifiers: string[]): any {
    if (!party || !party.locId || this.stringUtils.isBlank(party.locId) || !qualifiers || qualifiers.length === 0) {
      return null;
    }

    const filter = {
      path: 'segments',
      clause: 'must',
      joinType: 'or',
      criteria: []
    };

    qualifiers.forEach(qualifier => {
      filter.criteria.push({
        fields: {
          segmentId: 'N1',
          element_0: this.stringUtils.trimToEmpty(qualifier),
          element_3: this.stringUtils.trimToEmpty(party.locId)
        }
      });
    });

    return filter;
  }

  private buildTimeRange(type: string, start: any, end: any): any {
    console.log('buildtimerange - type:', type, 'start:',start, 'end',end);

    if (type !== 'custom') {
      const seconds = parseInt(type, 10);
      if (isNaN(seconds)) {
        throw new Error(`The time range value of '${type}' could not be converted to a number.`);
      }

      return {
        relative: Math.abs(seconds)
      };
    } else if (!moment(start).isValid()) {
      throw new Error(`The start time range value of '${start}' is invalid.`);
    } else if (!moment(end).isValid()) {
      throw new Error(`The end time range value of '${end}' is invalid.`);
    } else {
      return {
        start: moment(start).startOf('day').valueOf(),
        end: moment(end).endOf('day').valueOf()
      };
    }
  }

  private buildSort(orderBy: string): any {
    orderBy = this.stringUtils.trimToEmpty(orderBy);
    if (orderBy.indexOf('_score') > -1 || orderBy.length==0) {
      return '_score';
    }

    const sort: any = {};

    if (orderBy.indexOf(':') === -1) {
      sort[orderBy] = 'desc';
    } else {
      const fieldName = orderBy.substring(0, orderBy.indexOf(':'));
      sort[fieldName] = orderBy.substring(orderBy.indexOf(':') + 1);
    }
    return sort;
  }

  private buildDocumentTypeFilter(documentType: string): any | null {
    // Ensure documentType is trimmed and in lowercase
    documentType = this.stringUtils.trimToEmpty(documentType).toLowerCase();

    // Determine filter based on documentType
    switch (documentType) {
      case 'any':
        return null;
      case 'purbol':
        return {
          path: 'segments',
          clause: 'filter',
          joinType: 'or',
          criteria: [
            {
              fields: {
                segmentId: 'ST',
                element_0: '204'
              }
            },
            {
              fields: {
                segmentId: 'ST',
                element_0: '211'
              }
            }
          ]
        };
      case 'invoice':
        return {
          path: 'segments',
          clause: 'filter',
          joinType: 'or',
          criteria: [
            {
              fields: {
                segmentId: 'ST',
                element_0: '210'
              }
            }
          ]
        };
      case 'status':
        return {
          path: 'segments',
          clause: 'filter',
          joinType: 'or',
          criteria: [
            {
              fields: {
                segmentId: 'ST',
                element_0: '214'
              }
            }
          ]
        };
      case 'delimani':
        return {
          path: 'segments',
          clause: 'filter',
          joinType: 'or',
          criteria: [
            {
              fields: {
                segmentId: 'ST',
                element_0: '212'
              }
            }
          ]
        };
      default:
        throw new Error(`Unknown document type filter '${documentType}'.`);
    }
  }


  private buildReferenceNumberFilter(referenceNumber: any): any {
    if (!referenceNumber || (!referenceNumber.type && !referenceNumber.value)) {
      return null;
    } else if (this.stringUtils.isBlank(referenceNumber.type) && this.stringUtils.isBlank(referenceNumber.value)) {
      return null;
    }

    const filter = {
      path: 'segments',
      clause: 'must',
      joinType: 'or',
      criteria: []
    };

    const types = this.stringUtils.trimToEmpty(referenceNumber.type).split(',');

    types.forEach(type => {
      type = this.stringUtils.trimToEmpty(type).toUpperCase();
      if (type.length === 0) {
        this.addAllReferenceNumberFilters(filter.criteria, referenceNumber.value);
      } else {
        this.addReferenceNumberFilters(filter.criteria, type, referenceNumber.value);
      }
    });

    return filter.criteria.length > 0 ? filter : null;
  }

  private addAllReferenceNumberFilters(criteria: any[], refNbrTxt: string): void {
    if (!refNbrTxt || this.stringUtils.trimToEmpty(refNbrTxt).length === 0) {
      return;
    }

    Object.keys(this.IMPLIED_REF_NUMBER_LOCATIONS).forEach(typeCd => {
      this.IMPLIED_REF_NUMBER_LOCATIONS[typeCd].forEach(refLocation => {
        refLocation.value.forEach(valueOffset => {
          const fields: any = { segmentId: refLocation.segmentId };
          fields[`element_${valueOffset}`] = refNbrTxt;
          criteria.push({ fields });
        });
      });
    });

    this.REFERENCE_NUMBER_LOCATIONS.forEach(refLocation => {
      const fields: any = { segmentId: refLocation.segmentId };
      fields[`element_${refLocation.value}`] = refNbrTxt;
      criteria.push({ fields });
    });
  }

  private addReferenceNumberFilters(criteria: any[], typeCd: string, refNbrTxt: string): void {
    if (!typeCd || this.stringUtils.trimToEmpty(typeCd).length === 0) {
      return;
    }

    typeCd = this.stringUtils.trimToEmpty(typeCd).toUpperCase();
    refNbrTxt = this.stringUtils.trimToEmpty(refNbrTxt);

    if (this.IMPLIED_REF_NUMBER_LOCATIONS[typeCd]) {
      this.IMPLIED_REF_NUMBER_LOCATIONS[typeCd].forEach(refLocation => {
        refLocation.value.forEach(valueOffset => {
          const fields: any = { segmentId: refLocation.segmentId };
          fields[`element_${valueOffset}`] = refNbrTxt.length > 0 ? refNbrTxt : '*';
          criteria.push({ fields });
        });
      });
    }

    this.REFERENCE_NUMBER_LOCATIONS.forEach(refLocation => {
      const fields: any = { segmentId: refLocation.segmentId };
      fields[`element_${refLocation.type}`] = typeCd;
      if (refNbrTxt.length > 0) {
        fields[`element_${refLocation.value}`] = refNbrTxt;
      }
      criteria.push({ fields });
    });
  }

  private buildPartyFilter(party: any, qualifiers: string[]): any {
    if (!party) {
      return null;
    }

    const fields: any = {};

    if (this.stringUtils.isNotBlank(party.name1)) {
      fields.name1 = party.name1;
    }

    if (this.stringUtils.isNotBlank(party.name2)) {
      fields.name2 = party.name2;
    }

    if (this.stringUtils.isNotBlank(party.address)) {
      fields.address = party.address;
    }

    if (this.stringUtils.isNotBlank(party.city)) {
      fields.city = party.city;
    }

    if (this.stringUtils.isNotBlank(party.state)) {
      fields.state = party.state;
    }

    if (this.stringUtils.isNotBlank(party.postalCd)) {
      fields.postalCd = party.postalCd;
      if (fields.postalCd.length === 5 && this.stringUtils.isNumeric(fields.postalCd)) {
        fields.postalCd += '*';
      }
    }

    if (this.stringUtils.isNotBlank(party.country)) {
      fields.countryCd = party.country;
    }

    if (Object.keys(fields).length === 0) {
      return null;
    }

    const filter = {
      path: 'parties',
      clause: 'must',
      joinType: 'or',
      criteria: []
    };

    qualifiers.forEach(qualifier => {
      const fieldsCopy = { ...fields, qualifier };
      filter.criteria.push({ fields: fieldsCopy });
    });

    return filter;
  }


// Helper methods
  private buildQuery(advanced: any): string {
    let query = '';

    if (advanced.gsId.trim().length > 0) {
      // If it's an inbound query then the position of the GS ID changes.
      if (advanced.gsDirection.trim() === 'Sender') {
        query += 'GS:2' + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      } else if (advanced.gsDirection.trim() === 'Receiver') {
        query += 'GS:3' + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      } else {
        query += 'GS:' + ((advanced.tranTypCd === '204' || advanced.tranTypCd === '211') ? '2' : '3') + ':' + this.queryUtils.escape(advanced.gsId.trim(), true);
      }
    }

    if (advanced.tranTypCd.trim().length > 0) {
      query += ' ST:1:' + advanced.tranTypCd.trim();
    }

    query += ' ' + advanced.query;

    return query;
  }

}
