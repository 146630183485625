import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { MainComponent } from './main/main.component';
import {SearchComponent} from './search/search.component';

const routes: Routes = [
  /*{
    path: 'main',
    component: MainComponent,
    canActivate: [XpoAuthenticationGuard],
  },*/
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  { path: '', redirectTo: 'search', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
