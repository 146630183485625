import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ClientUtilsService} from './clientutils.service';

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  constructor(private clientUtilsService: ClientUtilsService) {}

 /* index(index: string, type: string, id: string | null, document: any): Observable<any> {
    const request = {
      index: this.checkNotNull(index, 'The index is required.'),
      type: this.checkNotNull(type, 'The type is required'),
      refresh: true,
      body: this.checkNotNull(document, 'The document is required.')
    };

    if (id !== undefined && id !== null) {
      request['id'] = id;
    }

    const client = this.getClient();
    console.log(client);
    return new Observable(observer => {
      client.index(request, (error: any, response: any) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(response);
        }
        observer.complete();
      });
    }).pipe(
      catchError(this.handleError)
    );
  }*/

  index(index: string, type: string, id: string | null, document: any): Promise<any> {
    const request = {
      index: this.checkNotNull(index, 'The index is required.'),
      type: this.checkNotNull(type, 'The type is required'),
      refresh: true,
      body: this.checkNotNull(document, 'The document is required.')
    };

    if (id !== undefined && id !== null) {
      request['id'] = id;
    }

    const client = this.getClient();

    return new Promise((resolve, reject) => {
      client.index(request, (error: any, response: any) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      });
    }).catch(this.handleError);
  }


  getClient(): any {
    return this.clientUtilsService.getClient();
  }

  queryStringSearch(index: string, type: string, queryString: string, size?: number, sort?: any): Observable<any> {
    const request = {
      index: index,
      body: {
        query: {
          query_string: {
            query: queryString,
            analyze_wildcard: true
          }
        },
        size: null as number | null,
        sort: null as any | null
      }
    };

    if (type) {
      request['type'] = type;
    }

    if (size) {
      request.body.size = size;
    }

    if (sort) {
      request.body.sort = sort;
    }

    return this.search(request);
  }

  search(request: any): Observable<any> {
    if (!request.ignoreUnavailable) {
      request.ignoreUnavailable = true;
    }

    if (!request.allowNoIndices) {
      request.allowNoIndices = true;
    }

    if (!request.searchType) {
      request.searchType = 'dfs_query_then_fetch';
    }
    console.log("elasticsearch.service.js search() request:");
    console.log(request);

    const client = this.getClient();
    return new Observable(observer => {
      client.search(request, (error: any, response: any) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(response);
        }
        observer.complete();
      });
    }).pipe(
      catchError(this.handleError)
    );
  }

  private checkNotNull(value: any, errorMessage: string): any {
    if (value === null || value === undefined) {
      throw new Error(errorMessage);
    }
    return value;
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError('Something went wrong; please try again later.');
  }
}
