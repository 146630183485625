import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdiPreviewService } from '../services/edipreview.service';
import { WebSearchService } from '../services/websearch.service';
import { LogService } from '../services/log.service';

@Component({
  selector: 'edi-preview',
  templateUrl: './edi-preview.component.html',
  styleUrls: ['./edi-preview.component.css']
})
export class EdiPreviewComponent implements OnInit {
  ediSegments = { separators: {}, data: '' };
  data: any = {};
  mode: 'formatted' | 'text' = 'formatted';
  ediText: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public ediData: any,
    private ediPreviewService: EdiPreviewService,
    private webSearch: WebSearchService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.handleDataUpdate(this.ediData);
  }

  handleDataUpdate(data: any): void {
    if (!data || !data.document) {
      this.logService.error('handleDataUpdate:', data);
      throw new Error('The event was missing the data.document property');
    }

    const document = data.document;
    this.logService.log('Preparing to preview', document, data.formatted);
    this.ediSegments = {
      separators: document.meta.separators,
      data: document.meta.source,
    };

    const results = this.webSearch.getResults([
      {
        source: data.response._source,
        index: data.response._index,
        type: data.response._type,
        id: data.response._id
      }
    ], null);

    this.data = {
      isaTimestamp: document.isa.timestampInMillis,
      usageInd: document.isa.usageInd,
      fileName: document.meta.originalFileName,
      ediFormat: document.meta.ediFormatType,
      gsIds: data.formatted.gsIds,
      referenceNumbers: results.length > 0 ? results[0].referenceNumbers : {},
      parties: results.length > 0 ? results[0].parties : [],
    };

    this.ediText = this.toText(this.ediSegments.separators, this.ediSegments.data);

    console.log('this.data', this.data);
  }


  getReferenceNumberKeys(referenceNumbers: { [key: string]: string[] }): string[] {
    return Object.keys(referenceNumbers);
  }

  toText(separators: any, source: string): string {
    const segments = source.split(separators.segment);
    for (let i = 0; i < segments.length; i++) {
      segments[i] = segments[i].trim();
    }

    return segments.join(separators.segment + '\n');
  }

  close(): void {
    this.ediPreviewService.close();
  }

  getPanelTitle(): string {
    const date = new Date(this.data.isaTimestamp).toISOString();
    return `${this.data.gsIds} – ${this.data.usageInd} – ${date}`;
  }

  getPanelSubTitle(): string {
    if (!this.data.fileName) return '';

    if (!this.data.ediFormat) {
      return this.data.fileName;
    }

    return `${this.data.fileName} (${this.data.ediFormat})`;
  }

  switchMode(): void {
    this.mode = this.mode === 'formatted' ? 'text' : 'formatted';
  }

  isArray(data: any): boolean {
    return Array.isArray(data);
  }
}
