import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private readonly MESSAGE_TTL = 15000;
  private messageIdCounter = 0;
  private messages: { [key: number]: { title: string, message: string, type: string, messageId: number } } = {};

  private messageSubject = new Subject<number>();
  messageRemoved$ = this.messageSubject.asObservable();

  constructor() {}

  addMessage(title: string, message: string, type: string): void {
    const messageId = this.getMessageId();

    this.messages[messageId] = {
      title,
      message,
      type: this.getMessageType(type),
      messageId,
    };

    setTimeout(() => {
      if (this.messages[messageId]) {
        delete this.messages[messageId];
        this.messageSubject.next(messageId);
      }
    }, this.MESSAGE_TTL);
  }

  removeMessage(messageId: number): void {
    if (this.messages[messageId]) {
      delete this.messages[messageId];
      this.messageSubject.next(messageId);
    }
  }

  private getMessageId(): number {
    return this.messageIdCounter++;
  }

  private getMessageType(type: string): string {
    if (typeof type !== 'string') {
      return 'inf';
    }

    switch (type.toLowerCase().trim()) {
      case 'err':
      case 'error':
        return 'err';
      case 'inf':
      case 'info':
        return 'inf';
      case 'warn':
      case 'warning':
      case 'wrn':
        return 'wrn';
      default:
        return 'inf';
    }
  }
}
