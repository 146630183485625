<div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start stretch">
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Name 1</mat-label>
      <input matInput [(ngModel)]="party.name1" />
    </mat-form-field>
  </div>
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Name 2</mat-label>
      <input matInput [(ngModel)]="party.name2" />
    </mat-form-field>
  </div>
</div>

<div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start stretch">
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Location ID</mat-label>
      <input matInput [(ngModel)]="party.locId" />
    </mat-form-field>
  </div>
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Address</mat-label>
      <input matInput [(ngModel)]="party.address" />
    </mat-form-field>
  </div>
</div>

<div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start stretch">
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>City</mat-label>
      <input matInput [(ngModel)]="party.city" />
    </mat-form-field>
  </div>
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>State code</mat-label>
      <input matInput [(ngModel)]="party.state" />
    </mat-form-field>
  </div>
</div>

<div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start stretch">
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Postal code</mat-label>
      <input matInput [(ngModel)]="party.postalCd" />
    </mat-form-field>
  </div>
  <div class="col m6 s12">
    <mat-form-field appearance="fill" class="compact-form-field">
      <mat-label>Country code</mat-label>
      <input matInput [(ngModel)]="party.country" />
    </mat-form-field>
  </div>
</div>
