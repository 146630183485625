import { Injectable } from '@angular/core';
import { ClientUtilsService } from '../util/clientutils.service';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchUtilsService {

  constructor(private clientUtils: ClientUtilsService) { }

  search(options: any): Observable<any> {
    const request = this.getRequest(options);
    // Convert the Promise returned by the client's search method to an Observable
    return from(this.clientUtils.getClient().search(request));
  }

  private getRequest(options: any): any {
    const request: any = {
      ignoreUnavailable: true,
      searchType: 'dfs_query_then_fetch',
      version: true
    };

    if (this.isDefined(options.index)) {
      request.index = options.index;
    }

    if (this.isDefined(options.type)) {
      request.type = options.type;
    }

    if (this.isNumber(options.from)) {
      request.from = options.from;
    }

    if (this.isNumber(options.size)) {
      request.size = options.size;
    }

    if (this.isDefined(options.sort)) {
      request.sort = options.sort;
    }

    if (this.isDefined(options.body)) {
      request.body = options.body;
    }

    return request;
  }

  private isDefined(val: any): boolean {
    return typeof val !== 'undefined';
  }

  private isNumber(val: any): boolean {
    return typeof val === 'number';
  }
}
