import { Injectable } from '@angular/core';
import { Client } from 'elasticsearch-browser';

@Injectable({
  providedIn: 'root'
})
export class ClientUtilsService {

  private credentials = {
    "elasticsearch": {
      "user": "javauser",
      "pwd": "javaxpo1"
    }
  };

  private client: any = null;
  private readonly ELASTICSEARCH_HOST = 'https://{{AUTHENTICATION}}elasticsearch-dev.ltl.xpo.com:9600';

  constructor() { }

  private getAuthCredentials() {
    return {
      user: this.credentials.elasticsearch.user,
      pwd: this.credentials.elasticsearch.pwd
    };
  }

  private getElasticsearchHost(credentials: any) {
    if (!credentials || !credentials.user || !credentials.pwd || credentials.user.length === 0 || credentials.pwd === 0) {
      return this.ELASTICSEARCH_HOST.replace('{{AUTHENTICATION}}', '');
    }

    var authentication = credentials.user + ':' + credentials.pwd + '@';
    return this.ELASTICSEARCH_HOST.replace('{{AUTHENTICATION}}', authentication);
  }

  getClient() {
    if (this.client !== null) {
      return this.client;
    }

    let credentials = this.getAuthCredentials();
    let host = this.getElasticsearchHost(credentials);

    this.client = new Client({
      host: host
    });

    return this.client;
  }
}
