import { Injectable } from '@angular/core';
import { Party } from '../model/result.model'
import { SimpleCriteria } from '../model/criteria.model';
//import { StringUtilsService } from 'utils/stringutils.service';

@Injectable({
  providedIn: 'root'
})
export class WebSearchService {
  private XPO_SCACS = {
    'CNWY': true,
    'CWCE': true,
    'CWSE': true,
    'CWWE': true,
    'XPOL': true
  };

  private NAME_SEGMENTS = { N1: true, N2: true, N3: true, N4: true };

  private NAME_QUALIFIERS = {
    SE: ['Selling Party', 0],
    SH: ['Shipper', 1],
    SF: ['Ship From', 2],
    PW: ['Pickup Address', 3],
    FW: ['Forwarder', 4],
    VN: ['Vendor', 5],
    SU: ['Supplier', 6],
    CN: ['Consignee', 7],
    UC: ['Ultimate Consignee', 8],
    ST: ['Ship To', 9],
    OB: ['Ordered By', 10],
    MA: ['Intended Party', 11],
    BT: ['Bill-to', 12],
    BS: ['Bill and Ship To', 13],
    BY: ['Buying Party', 14],
    RE: ['Invoice Receiver', 15],
    PF: ['Freight Bill Receiver', 16],
    CS: ['Consolidator', 17],
    XQ: ['Canadian Customs Broker', 18],
    XR: ['Mexican Customs Broker', 19],
    XU: ['US Customs Broker', 20],
    SN: ['Store', 21],
    AG: ['Buying Agent', 22],
    WH: ['Warehouse', 23],
    CA: ['Carrier', 24]
  };

  //constructor(private stringUtils: StringUtilsService) {}
  constructor() {}

  /**
   * Formats the search results for the search controller.
   *
   * @param {any[]} results - Array of results to format.
   * @param {string | null} resultType - Either 'invoice', 'purbol', 'status', or null.
   * @returns {any[]} - Formatted list of results.
   */
  getResults(results: any[], resultType: string | null): any[] {
    resultType = typeof resultType === 'string' ? resultType.trim().toLowerCase() : null;

    const list: any[] = [];
    for (let index = 0; index < results.length; index++) {
      list.push(this.getResult(index, results[index], resultType));
    }

    return list;
  }


  /**
   * Returns a single result with various properties pulled out for easy access.
   *
   * @param {number} index The index of the result.
   * @param {object} result The result object.
   * @param {string} resultType The type of result (invoice, purbol, status, or null).
   * @returns {object} The formatted result.
   */
  getResult(index: number, result: any, resultType: string): any {
    // Determine the transaction type so we can adjust the result data.
    const tranTyp = ''; //this.getSegmentValues(result.source.segments, 'ST', 0).join(', ');

    // Otherwise it's outbound, so we format it like so:
    const segments = result.source.segments;
    const formattedResult = {
      meta: {
        index: result.index,
        type: result.type,
        id: result.id,
        source: result.source
      },
      isaId: this.getIsaId(result.source.isa),
      isa: {
        senderId: result.source.isa.senderId,
        receiverId: result.source.isa.receiverId
      },
      gsId: this.getGsIds(segments, false),
      gs: this.getGsIds(segments, true),
      isaTimestamp: new Date(result.source.isa.timestampInMillis),
      usageInd: result.source.isa.usageInd,
      referenceNumbers: {} as { [key: string]: string[] },
      parties: this.getParties(segments),
      resultIndex: index,
      isInbound: false,
      tranTyp: tranTyp,
      statusTyp: this.getStatus(segments, tranTyp),
      proNumber: '',
      shipmentId: ''
    };
    formattedResult.referenceNumbers = this.getReferenceNumbers(segments);

    // Extract a special reference number.
    let removeRefNbrTxt = null;
    let removeRefNbrType = null;
    if (resultType === 'invoice') {
      const refNbrs = {};
      this.extractRefNbrs(segments, 'B3', 1, 'PRO', refNbrs);

      formattedResult.proNumber = refNbrs['PRO'] ? refNbrs['PRO'][0] : '';

      if (formattedResult.proNumber.length > 0) {
        removeRefNbrTxt = formattedResult.proNumber;
        removeRefNbrType = 'PRO';
      }
    } else if (resultType === 'status') {
      const refNbrs = {};
      this.extractRefNbrs(segments, 'B10', 0, 'PRO', refNbrs);

      formattedResult.proNumber = refNbrs['PRO'] ? refNbrs['PRO'][0] : '';

      if (formattedResult.proNumber.length > 0) {
        removeRefNbrTxt = formattedResult.proNumber;
        removeRefNbrType = 'PRO';
      }
    } else if (resultType === 'purbol') {
      const refNbrs = {};
      this.extractRefNbrs(segments, 'B2', 3, 'SN#', refNbrs);
      this.extractRefNbrs(segments, 'BOL', 2, 'SN#', refNbrs);

      formattedResult.shipmentId = refNbrs['SN#'] ? refNbrs['SN#'][0] : '';

      if (formattedResult.shipmentId.length > 0) {
        removeRefNbrTxt = formattedResult.shipmentId;
        removeRefNbrType = 'SN#';
      }
    }

    // Remove one if it became a specific proNumber or shipmentId.
    if (removeRefNbrType && removeRefNbrTxt) {
      const referenceNumbers = {};
      for (const typeCd of Object.keys(formattedResult.referenceNumbers)) {
        // If it isn't the type we're going to remove from, copy it over.
        if (typeCd !== removeRefNbrType) {
          referenceNumbers[typeCd] = formattedResult.referenceNumbers[typeCd];
        } else {
          // Otherwise collect only the ones which don't match.
          const values = [];
          for (const refNbrTxt of formattedResult.referenceNumbers[typeCd]) {
            if (refNbrTxt === removeRefNbrTxt) {
              continue;
            }

            values.push(refNbrTxt);
          }

          // Only add it back if there are values in it.
          if (values.length > 0) {
            referenceNumbers[typeCd] = values;
          }
        }
      }

      formattedResult.referenceNumbers = referenceNumbers;
    }

    return formattedResult;
  }

  /**
   * Extracts all reference numbers from the segments.
   *
   * @param {array} segments The segments array.
   * @return {object} An object where the key is the reference type code and the value is an array of
   *                  reference numbers of that type.
   */
  getReferenceNumbers(segments: any[]): { [key: string]: string[] } {
    const refNbrs: { [key: string]: string[] } = {};

    this.extractRefNbrs(segments, 'B10', 0, 'PRO', refNbrs);
    this.extractRefNbrs(segments, 'L11', 0, 1, refNbrs);
    this.extractRefNbrs(segments, 'MAN', 1, 0, refNbrs);
    this.extractRefNbrs(segments, 'N9', 1, 0, refNbrs);
    this.extractRefNbrs(segments, 'B3', 1, 'PRO', refNbrs);
    this.extractRefNbrs(segments, 'B3', 2, 'SN#', refNbrs);
    this.extractRefNbrs(segments, 'B1', 1, 'SN#', refNbrs);
    this.extractRefNbrs(segments, 'B10', 1, 'SN#', refNbrs);
    this.extractRefNbrs(segments, 'AK1', 1, 'BATCH#', refNbrs);
    this.extractRefNbrs(segments, 'B2', 3, 'SN#', refNbrs);
    this.extractRefNbrs(segments, 'BOL', 2, 'SN#', refNbrs);
    this.extractRefNbrs(segments, 'SPO', 0, 'PO#', refNbrs);
    this.extractRefNbrs(segments, 'OID', 1, 'PO#', refNbrs);

    // Sort the reference numbers, but first deduplicate them.
    for (const key of Object.keys(refNbrs)) {
      const deduped = [];
      const found = new Set();
      for (const refNbr of refNbrs[key]) {
        if (found.has(refNbr)) {
          continue;
        }

        deduped.push(refNbr);
        found.add(refNbr);
      }

      refNbrs[key] = deduped;
      refNbrs[key].sort();
    }

    const sorted: { [key: string]: string[] } = {};
    const keys = Object.keys(refNbrs);
    keys.sort();
    for (const key of keys) {
      sorted[key] = refNbrs[key];
    }
    return refNbrs;
  }

  /**
   * Extracts reference numbers from segments.
   *
   * @param {array} segments The segments array.
   * @param {string} segmentId The segment ID.
   * @param {number} valuePosition The element offset, 0-based.
   * @param {number|string} labelPosition The position to get the label from.
   *                                      If it is a string that is used instead.
   * @param {object} refNbrs An object which will be added to where the key is the reference label (PO, PO#,
   *                         etc) and the value is an array of those reference number types.
   */
  extractRefNbrs(segments: any[], segmentId: string, valuePosition: number, labelPosition: number | string, refNbrs: { [key: string]: string[] }): void {
    for (const segment of segments) {
      if (!segment.segmentId || segment.segmentId.trim().toUpperCase() !== segmentId) {
        continue;
      }

      const refNbr = segment['element_' + valuePosition];
      if (!refNbr || refNbr.trim().length === 0) {
        continue;
      }

      const label = (typeof labelPosition === 'string' ? labelPosition : segment['element_' + labelPosition]).toUpperCase().trim();
      //const label = StringUtils.trimToEmpty(typeof labelPosition === 'string' ? labelPosition : segment['element_' + labelPosition]).toUpperCase();
      if (!refNbrs[label]) {
        refNbrs[label] = [];
      }

      refNbrs[label].push(refNbr.trim());
    }
  }
  /**
   * Returns the parties on the shipment.
   *
   * @param {any[]} segments An array of segments.
   * @returns {any[]} An array of parties.
   */
  getParties(segments: any[]): any[] {
    const groups = [];
    let buffer = {};
    for (const segment of segments) {
      if (!this.isDefined(this.NAME_SEGMENTS[segment.segmentId])) {
        continue;
      } else if (segment.segmentId === 'N1' && Object.keys(buffer).length > 0) {
        groups.push(buffer);
        buffer = {};
      }

      buffer[segment.segmentId] = segment;
    }

    if (Object.keys(buffer).length > 0) {
      groups.push(buffer);
    }

    const parties: Party[] = [];
    const partyIds = {};
    for (const group of groups) {
      const party = this.getParty(group);
      if (!party || partyIds[party.id]) {
        continue;
      }

      parties.push(party);
      partyIds[party.id] = true;
    }

    parties.sort((a, b) => (a.ranking > b.ranking ? 1 : a.ranking < b.ranking ? -1 : 0));

    return parties;
  }

  /**
   * Builds a party object with a party type, name, and address.
   *
   * @param {any} group An object which should have at least an N1 property which is an N1 segment.
   * @returns {any} An object with a name, type, address, and text.
   *                Returns null if there is no N1.
   */
  getParty(group: any): any {
    if (!group || !group.N1) {
      return null;
    }

    const party = {} as Party;


    const partyQual = this.trim(group.N1.element_0, true);
    party.type = this.NAME_QUALIFIERS[partyQual] ? this.NAME_QUALIFIERS[partyQual][0] : partyQual;
    party.ranking = this.NAME_QUALIFIERS[partyQual] ? this.NAME_QUALIFIERS[partyQual][1] : 99;

    party.name1 = group.N1.element_1 ? group.N1.element_1 : 'Unknown';

    if (group.N2) {
      party.name2 = this.trim(group.N2.element_0, true);
      if (group.N2.element_1) {
        party.name2 += (party.name2.length > 0 ? ' ' : '') + this.trim(group.N2.element_1, true);
      }
    }

    party.locId = [] as string[]
    if (group.N1 && group.N1.element_3 && group.N1.element_3.trim()) {
      party.locIdQualifier = group.N1.element_2 ? this.trim(group.N1.element_2, true) : '';
      party.locId.push(this.trim(group.N1.element_3, true));
    }

    if (group.N3) {
      party.address = this.trim(group.N3.element_0, true);
      if (group.N3.element_1) {
        party.address += (party.address.length > 0 ? ' ' : '') + this.trim(group.N3.element_1, true);
      }
    }

    if (group.N4) {
      party.city = this.trim(group.N4.element_0, true);
      party.state = this.trim(group.N4.element_1, true);
      party.postalCd = this.trim(group.N4.element_2, true);
      party.countryCd = this.trim(group.N4.element_3, true);
    }

    const texts = this.removeBlanks([party.name1, party.name2, party.address, party.city, party.state, party.postalCd, party.countryCd]);
    party.cityStateText = this.removeBlanks([party.city, party.state, party.postalCd, party.countryCd]).join(', ');
    party.text = texts.join(', ') + (party.locId.length > 0 ? ' (Location ID: ' + party.locId + ')' : '');
    party.id = party.type + ':' + party.text.replace(/\s+/g, '').trim();

    return party;
  }

  private isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }

  private trim(str: string, upperCase: boolean): string {
    if (!this.isDefined(str)) {
      return '';
    }

    str = str.trim();
    return upperCase ? str.toUpperCase() : str;
  }

  private removeBlanks(texts: string[]): string[] {
    if (!texts || texts.length === 0) {
      return [];
    }

    return texts.filter(entry => entry && entry.trim() !== '');
  }

  /**
   * Returns the GS IDs on the EDI.
   * @param  {array} segments The segments array.
   * @param {boolean} buildObject Flag to indicate whether to return the GS IDs as an object.
   * @return {string|object} The GS IDs as a string or an object if buildObject is true.
   */
  getGsIds(segments: any[], buildObject: boolean): string | { senderId: string, receiverId: string } {
    const senderIds = this.getSegmentValues(segments, 'GS', 1, true);
    const receiverIds = this.getSegmentValues(segments, 'GS', 2, true);

    if (buildObject) {
      return {
        senderId: typeof senderIds === 'string' ? senderIds : senderIds.join(', '),
        receiverId: typeof receiverIds === 'string' ? receiverIds : receiverIds.join(', ')
      };
    }

    const gsIds = {};
    for (const gsId of senderIds) {
      if (!this.XPO_SCACS.hasOwnProperty(gsId) && !gsIds.hasOwnProperty(gsId)) {
        gsIds[gsId] = true;
      }
    }

    for (const gsId of receiverIds) {
      if (!this.XPO_SCACS.hasOwnProperty(gsId) && !gsIds.hasOwnProperty(gsId)) {
        gsIds[gsId] = true;
      }
    }

    if (Object.keys(gsIds).length === 0) {
      for (const gsId of senderIds) {
        gsIds[gsId] = true;
      }

      for (const gsId of receiverIds) {
        gsIds[gsId] = true;
      }
    }

    return Object.keys(gsIds).join(', ');
  }

  /**
   * Returns the appropriate ISA.
   *
   * @param  {object} isa The ISA object.
   * @return {string} The appropriate ISA ID.
   */
  getIsaId(isa: any): string {
    const sender = isa.senderId;
    const receiver = isa.receiverId;

    const isas = [];
    if (!this.XPO_SCACS.hasOwnProperty(sender)) {
      isas.push(sender);
    }

    if (!this.XPO_SCACS.hasOwnProperty(receiver)) {
      isas.push(receiver);
    }

    if (isas.length === 0) {
      isas.push(sender);
      isas.push(receiver);
    }

    return isas.join(', ');
  }

  /**
   * Returns the status based on the transaction type.
   *
   * @param {array} segments The segments array.
   * @param {string} tranTyp The transaction type.
   * @returns {string} The status.
   */
  getStatus(segments: any[], tranTyp: string): string {
   // let status = '';
    var status;

    if (tranTyp === '204' || tranTyp === '211') {
      status = this.getSegmentValues(segments, 'B2A', 0);
    } else if (tranTyp === '214') {
      status = this.getSegmentValues(segments, 'AT7', 0);
    } else if (tranTyp === '990') {
      status = this.getSegmentValues(segments, 'B1', 4);
    } else if (tranTyp === '210') {
      status = this.getSegmentValues(segments, 'B3', 7);
    } else {
      status = '';
    }

    if (status === 'null') {
      status = '';
    }

    return status;
  }

  /**
   * Returns the element values based on a segment ID and position.
   *
   * @param  {array} segments The segments array.
   * @param  {string} segmentId The segment ID.
   * @param  {number} elementIndex The element index, 0-based.
   * @param  {boolean?} asArray Flag to return values as an array.
   * @return {array|string} The element values.
   */
  getSegmentValues(segments: any[], segmentId: string, elementIndex: number, asArray?: boolean): string |any[] {
    segmentId = segmentId.trim().toUpperCase();
    const matches = {};
    for (const segment of segments) {
      if (segmentId !== segment.segmentId.trim().toUpperCase()) {
        continue;
      }

      const value = segment['element_' + elementIndex];
      if (value === undefined || (typeof value === 'string' && value.length === 0)) {
        continue;
      } else if (matches.hasOwnProperty(value)) {
        continue;
      }

      matches[value] = true;
    }

    return asArray ? Object.keys(matches) : Object.keys(matches).join(', ');
  }
}
