import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; // Replace with your dialog service
import { BatchTaskService } from '../../services/batchtask.service'; // Update path as needed
import { LogService } from '../../services/log.service'; // Update path as needed
import { SegmentService } from '../../services/segment.service'; // Update path as needed
import { MessageService } from '../../services/message.service'; // Update with the correct path
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Criteria, SimpleCriteria, AdvancedCriteria, BatchCriteria } from '../../model/criteria.model';
@Component({
  selector: 'app-search-criteria',
  templateUrl: './search-criteria.template.html',
  styleUrls: ['./search-criteria.component.css'] // if any styles are needed
})
export class SearchCriteriaComponent implements OnInit {

  @Input() criteria: Criteria;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  isWebMode: boolean = true;
  showHelpType: any = null;
  helpDialog: any = null;
  segments: any[] = [];
  segment: any[] = [];

  selectedTabIndex = 0; // Active tab index

  constructor(
    private dialog: MatDialog,
    private batchTaskService: BatchTaskService,
    private logger: LogService,
    private segmentService: SegmentService,
    private messageService : MessageService

  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
  }

  initBatch(batch: any) {
    if (typeof batch !== 'object') {
      this.logger.warn('The criteria.simple property was not an object, it was overridden.');
      batch = {};
    }

    batch.tranType = batch.tranType || '';
    batch.ids = batch.ids || '';
    batch.idType = batch.idType || '';
    batch.proWild = batch.proWild || '';
  }

  initSimple(simple: SimpleCriteria): SimpleCriteria {
    if (typeof simple !== 'object') {
      this.logger.warn('The criteria.simple property was not an object, it was overridden.');
      simple = simple || {} as SimpleCriteria;
    }

    simple.shipper = this.initParty(simple.shipper);
    simple.consignee = this.initParty(simple.consignee);
    simple.billTo = this.initParty(simple.billTo);
    simple.partyType = simple.partyType || '';

    simple.documentType =  'purbol';
    simple.usageInd = 'p';
    simple.isaSenderId = simple.isaSenderId || '';
    simple.isaReceiverId = simple.isaReceiverId || '';
    simple.gsSenderId = simple.gsSenderId || '';
    simple.gsReceiverId = simple.gsReceiverId || '';
    simple.referenceNumbers = this.initReferenceNumbers(simple.referenceNumbers);

    simple.timeRange= '604800';
    simple.startTimeRange = simple.startTimeRange || null;
    simple.endTimeRange = simple.endTimeRange || null;
    simple.orderBy = "isa.timestamp:desc";

    return simple;
  }

  /**
   * Initializes an advanced search criteria, merging with any existing values on the advanced object passed.
   * @param advanced The advanced criteria object to use.
   * @returns The advanced criteria object.
   */
  initAdvanced(advanced: any): any {
    if (typeof advanced !== 'object' || advanced === null) {
      this.logger.warn('The criteria.advanced property was not an object, it was overridden.');
      advanced = {};
    }

    // General filters/queries
    advanced.gsDirection = advanced.gsDirection || 'Default';
    advanced.gsId = advanced.gsId || '';
    advanced.tranTypCd = advanced.tranTypCd || '';
    advanced.query = advanced.query || '';
    advanced.excludeAccepted = advanced.excludeAccepted || false;
    advanced.usageIndFilter = advanced.usageIndFilter || 'any';
    advanced.orderBy = advanced.orderBy || 'isa.timestamp:desc';

    // Time range
    advanced.timeRange = advanced.timeRange || '604800';
    advanced.startTimeRange = advanced.startTimeRange || null;
    advanced.endTimeRange = advanced.endTimeRange || null;

    // Inbound specific filters
    advanced.invertInboundReq = advanced.invertInboundReq || false;
    advanced.reqProdBolMatch = advanced.reqProdBolMatch || false;
    advanced.reqTestBolMatch = advanced.reqTestBolMatch || false;
    advanced.reqProdPurMatch = advanced.reqProdPurMatch || false;
    advanced.reqTestPurMatch = advanced.reqTestPurMatch || false;
    advanced.reqProdLoadTenderMatch = advanced.reqProdLoadTenderMatch || false;
    advanced.reqTestLoadTenderMatch = advanced.reqTestLoadTenderMatch || false;
    advanced.reqTibcoBwLogs = advanced.reqTibcoBwLogs || false;
    advanced.reqCfgHldErrors = advanced.reqCfgHldErrors || false;

    // Chip list (ignore fields for comparison)
    advanced.defaultIgnoreFields = this.defaultIgnoreFields();
    advanced.ignoreFieldList = this.initIgnoreFields();
    advanced.ignoreFieldSelected = null;
    advanced.searchField = null;
    advanced.selectedIgnoreFieldList = advanced.selectedIgnoreFieldList || [];

    return advanced;
  }

  initParty(party: any) {
    if (typeof party !== 'object') {
      party = {};
    }

    party.name1 = party.name1 || '';
    party.name2 = party.name2 || '';
    party.address = party.address || '';
    party.city = party.city || '';
    party.state = party.state || '';
    party.postalCd = party.postalCd || '';
    party.country = party.country || '';
    party.locId = party.locId || '';

    return party;
  }
  /**
   * Initializes the reference numbers.
   * @param refs
   * @returns {Array}
   */
    initReferenceNumbers(refs: any[]): { type: string, value: string }[] {
    // Check if the passed 'refs' is an array, else initialize as an empty array
    if (!Array.isArray(refs)) {
      refs = [];
    }

    const referenceNumbers = [];

    // Loop through the refs array
    for (let ref of refs) {
      const entry = {
        type: ref.type || '',
        value: ref.value || ''
      };

      // Skip empty entries
      if (this.isEmpty(entry.type) && this.isEmpty(entry.value)) {
        continue;
      }

      referenceNumbers.push(entry);
    }

    // If no valid reference numbers were found, push a default empty object
    if (referenceNumbers.length === 0) {
      referenceNumbers.push({
        type: '',
        value: ''
      });
    }

    return referenceNumbers;
  }

  /**
   * Utility function to check if a string is empty or undefined.
   * @param str
   * @returns {boolean}
   */
  isEmpty(str: string): boolean {
    return !str || str.trim().length === 0;
  }


  getExportableSegments(type) {
    if (type === 'purbol') {
      return this.segmentify(this.segmentService.getPurBolSegments());
    } else if(type === 'status')
      return this.segmentify(this.segmentService.getStatusSegments());
    else {//if(type === 'invoice')
      return this.segmentify(this.segmentService.getInvoiceSegments());
    }
  }

  setDocumentType(type: string): void {
    this.criteria.simple.documentType = type;
    this.criteria.simple.displaySegments = this.getDisplayableSegments(type);
  }

  /**
   * Indicates whether the query is against a set of inbound documents.
   * @returns {boolean} Returns {@code true} if the transaction set being searched for is specifically a 204 or
   *     211.
   */
  isInboundQuery()
  {
    if (this.criteria.mode === 'advanced') {
      return this.criteria.advanced.tranTypCd === '204' || this.criteria.advanced.tranTypCd === '211';
    }
    else {
      // TODO
      return false;
    }
  }
  addReferenceNumber()
  {
    this.criteria.simple.referenceNumbers.push({
      type: '',
      value: ''
    });
  }

  removeReferenceNumber(referenceNumber: any)
  {
    let index = this.criteria.simple.referenceNumbers.indexOf(referenceNumber);
    if (index < 0) {
      return;
    }

    this.criteria.simple.referenceNumbers.splice(index, 1);
  }

  /**
   * Invokes the searchCallback if defined on the criteria object.
   */
  search(caller: any): void {
    if (!this.criteria || typeof this.criteria.searchCallback !== 'function') {
      this.logger.warn('There is no criteria.searchCallback registered.');
      return;
    }

    this.onSearch.emit(caller);

    this.criteria.searchCallback();
  }

  /**
   * Clears the current search criteria.
   */
  clear()
  {
    this.criteria.simple = this.initSimple({});
    this.criteria.advanced = this.initAdvanced({});
    this.criteria.displayResults=false;
  }

  closeHelp(): void {
    this.dialog.closeAll();
  }

  openHelp(type)
  {
    //yikes, deal with this alter.
  }

  handleSubmit(event: Event): void {
    event.preventDefault();
  }

  orderIgnoreFields () {
    this.criteria.advanced.selectedIgnoreFieldList = this.criteria.advanced.selectedIgnoreFieldList.sort();
  }

  orderExportFields () {
    this.criteria.simple.selectedExportFieldList = this.criteria.simple.selectedExportFieldList;
  }

  /**
   * Create filter function for a query string
   */
  createFilterFor(query: string): (field: string) => boolean {
    return (field: string): boolean => {
      return field.toUpperCase().indexOf(query.toUpperCase()) === 0;
    };
  }

  segmentify(segments: any[] ) {
    let segmented =[];
    for( let i=0; i < segments.length; i++) {
      let elNum = segments[i].element > 9 ?  segments[i].element : '0' + segments[i].element;
      segmented[i]=segments[i].segment + ':' + elNum;
    }

    return segmented;
  }

  getDisplayableSegments(type: string) {
    if(type === 'purbol')
      return this.dedupedSegmentify(this.segmentService.getPurBolSegments());
    else if(type === 'status')
      return this.dedupedSegmentify(this.segmentService.getStatusSegments());
    else {// if(type === 'invoice')
      return this.dedupedSegmentify(this.segmentService.getInvoiceSegments());
    }
  }

  dedupedSegmentify(segments) {
    let segmented =[];
    for( let i=0; i < segments.length; i++) {
      segmented[i]=segments[i].comment;

    }
    return segmented;
  }

  defaultIgnoreFields() {
    return ['ISA:09','ISA:10','ISA:11','ISA:13','ISA:15',
      'GS:04','GS:05','GS:06',
      'ST:02',
      'SE:02',
      'GE:01','GE:02',
      'IEA:02',
      'B3:11','B3:12',
      'N4:03','N4:04',
      'L1:08'
    ];
  }

  /**
   * Initializes the fields from the documents. TODO: move this to a resource file.
   */
  initIgnoreFields() {
    return [
      'ISA:01','ISA:02','ISA:03','ISA:04','ISA:05',
      'ISA:06','ISA:07','ISA:08','ISA:09','ISA:10',
      'ISA:11','ISA:12','ISA:13','ISA:14','ISA:15',
      'GS:01','GS:02','GS:03','GS:04',
      'GS:05','GS:06','GS:07','GS:08',
      'ST:01','ST:02',
      'B3:01','B3:02','B3:03','B3:04','B3:05','B3:06','B3:07',
      'B10:01','B10:02','B10:03',
      'L1:08',
      'L5:01','L5:02',
      'L7:01','L7:02',
      'L11:01','L11:02',
      'N1:01','N1:02',
      'N3:01','N3:02',
      'N4:01','N4:02','N4:03','N4:04',
      'N7:01','N7:02','N7:03','N7:04','N7:05','N7:06','N7:07','N7:08','N7:09','N7:12',
      'N9:01','N9:02',
      'G62:01','G62:02','G62:03','G62:04',
      'LX:01',
      'A7:01','A7:02','A7:03','A7:04','A7:05',
      'A7:06','A7:07',
      'MS1:01','MS1:02',
      'MS2:01','MS2:02',
      'AT8:01','AT8:02','AT8:03','AT8:04','AT8:05',
      'SE:01','SE:02',
      'GE:01','GE:02',
      'IEA:01','IEA:02',
      'R3:01','R3:02','R3:03'
    ];
  }

  resetViews() {
    this.clear();
    this.criteria.displayResults=false;
  }

  post(): void {
    const tranType = this.isString(this.criteria.batch.tranType) ? decodeURIComponent(this.criteria.batch.tranType.trim().toUpperCase()) : '';
    const ids = this.isString(this.criteria.batch.ids) ? decodeURIComponent(this.criteria.batch.ids.trim().toUpperCase()) : '';
    const email = this.isString(this.criteria.batch.email) ? decodeURIComponent(this.criteria.batchemail.trim().toUpperCase()) : '';

    const idType = this.isString(this.criteria.batch.idType) && tranType !== 'INVOICE' ? decodeURIComponent(this.criteria.batch.idType.trim().toUpperCase()) : 'pro';
    const refCode = this.isString(this.criteria.batch.refCode) ? decodeURIComponent(this.criteria.batch.refCode.trim().toUpperCase()) : '';

    const reportType = 'EDI_REPORT';

    if (this.validate(email, ids)) {
      this.batchTaskService.create(reportType, {
        tranType: tranType,
        ids: ids,
        email: email,
        idType: idType,
        refCode: refCode,
        proWild: this.criteria.batchproWild,
      }).subscribe({
        next: (response) => {
          this.messageService.addMessage(
            'Report Queued',
            'The report was queued, and should be emailed shortly.',
            'info'
          );
        },
        error: (error) => {
          this.messageService.addMessage(
            'Failed to Create Request',
            'An error occurred while trying to request a report to be generated, check the console for more information.',
            'error'
          );
          console.error(error);
        }
      });
    }
  }

  private isString(value: any): value is string {
    return typeof value === 'string';
  }

  addSegment(){
    this.segments.push(this.segment)
  }
  removeSegment(x){
    this.segments.splice(x,1);
  }
  validate(email: string, ids: string): boolean {
    let validated = true;

    // Regular expression for email validation
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(email)) {
      this.messageService.addMessage('this is the title','The provided email is not valid. Please enter a valid email.', 'error');
      validated = false;
    }

    if (!ids || ids.trim().length === 0) {
      this.messageService.addMessage('this is the title','You must include ids to search by.', 'error');
      validated = false;
    }

    return validated;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    if (event.index === 0) {
      this.clear();
      this.criteria.mode='simple';
    } else if(event.index === 1) {
      this.clear();
      this.criteria.mode='advanced';
    } else {
      this.criteria.mode='reports';
    }
    this.resetViews();
  }






}

