import { Component, Input, Output, EventEmitter} from '@angular/core';
import {FormsModule} from '@angular/forms'
import {PartyCriteria} from '../../../model/criteria.model';


@Component({
  selector: 'party-criteria',
  templateUrl: './party-criteria.template.html', // make sure the path is correct
  styleUrls: ['./party-criteria.css'],
})
export class PartyCriteriaComponent {

  // Use Angular's Input for partyType and party bindings
  @Input() partyType: string;
  @Input() party: PartyCriteria;

  ngOnInit() {
  }
  constructor() {}

}

