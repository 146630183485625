<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>XPO</xpo-header-app-name>
  <xpo-header-sub-app-name>EDI Search</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button routerLink="search" routerLinkActive="xpo-selected">Search</button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="root-container">
    <div class="root-container-main">
      <router-outlet></router-outlet>
    </div>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<!-- <app-edi-preview *ngIf="isPreviewVisible" ></app-edi-preview> -->
<xpo-app-notification></xpo-app-notification>
