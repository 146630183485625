import { Injectable } from '@angular/core';
import { SearchUtilsService } from './searchutils.service';
import { LogService } from './log.service';
import moment from 'moment';
import { Observable, from, throwError, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SimpleCriteria, Criteria } from '../model/criteria.model';


interface TimeRange {
  relative?: number;
  start?: string | Date;
  end?: string | Date;
}

interface RangeResult {
  start: moment.Moment;
  end: moment.Moment;
}
@Injectable({
  providedIn: 'root'
})
export class SegmentSearch2Service {
  // Flip this to true once weekly indexing is done.
  readonly INDEX_IS_WEEKLY = true;

  // The format to use.
  readonly INDEX_DATE_FORMAT: string[] = this.INDEX_IS_WEEKLY ? ['gggg.w'] : ['YYYY.MM.DD'];

  // The amount of time to step forward when building index names.
  readonly INDEX_STEP = 86400;

  // The template to use when building the pattern.
  readonly INDEX_TEMPLATE = 'b2b_edi_' + (this.INDEX_IS_WEEKLY ? 'data_' : '') + '{{TIMESTAMP}}';

  // The variable we will replace.
  readonly INDEX_TEMPLATE_VAR = '{{TIMESTAMP}}';

  // The supported clauses of a bool query.
  readonly BOOL_CLAUSES = { must: true, should: true, must_not: true, filter: true };

  constructor(
    private searchUtils: SearchUtilsService,
    private logger: LogService
  ) {}

  /**
   * Performs a search which uses nested queries to do complex join queries on the segments and parties arrays in
   * an EDI document.
   *
   * @param options See the segmentsearch2-options.json file, which is an example of an entire options object.
   * @returns {Observable<any>} An observable which emits the results or the error.
   * @throws Error if {@code options} is not an object.
   */
  search(options: Criteria): Observable<any> {
    if (!options || typeof options !== 'object') {
      return throwError(new Error('The options must be an object.'));
    }

    return this.executeSearch(options);
  }

  /**
   * Executes the search request and returns the result as an observable.
   *
   * @param options The search options.
   * @returns {Observable<any>} The search results observable.
   */
  executeSearch(options: Criteria): Observable<any> {
    return of(this.buildRequest(options)).pipe(
      tap((request) => {
        this.logger.info('Issuing request', request);
      }),
      switchMap((request) =>
        from(this.searchUtils.search(request)).pipe(
          map((response) => this.formatResponse(response, undefined)),
          catchError((error) => {
            this.logger.error('Search error:', error);
            return throwError(error);
          })
        )
      )
    );
  }

  /**
   * Formats the response from a search operation.
   * @param {any} response The response object from the search operation.
   * @param {any} error The error object if the search operation encountered an error.
   */
  formatResponse(response: any, error: any): any {
    if (error !== undefined) {
      // Handle error messages here.
      return;
    }

    if (response._shards?.failures?.length > 0) {
      const failures = response._shards.failures;
      failures.forEach(failure => {
        // Handle shard failure here.
      });
    }

    return this.formatResults(response);
  }

  /**
   * Formats the search results from the Elasticsearch response.
   * @param {any} response The response object from Elasticsearch search operation.
   * @returns {any} Formatted search results.
   */
  formatResults(response: any): any {
    const results = {
      total: response.hits.total,
      results: response.hits.hits.map((hit: any) => ({
        index: hit._index,
        type: hit._type,
        id: hit._id,
        score: hit._score,
        version: hit._version,
        source: hit._source
      }))
    };

    return results;
  }

  /**
   * Builds the Elasticsearch request payload based on the provided options.
   * @param {any} options The search options.
   * @returns {any} The constructed Elasticsearch request payload.
   */
  buildRequest(options: Criteria): any {
    let request: any = {
      index: "b2b_edi_data_2024.42",//this.buildIndexNames(options),
      size: typeof options.size === 'number' ? Math.min(options.size, 10000) : 200,
      from: typeof options.from === 'number' && options.from >= 0 ? options.from : 0,
      body: {
        sort: options.sort != null ? options.sort : ['_score'],
        query: {
          bool: {
            must: [],
            should: [],
            must_not: [],
            filter: []
          }
        }
      }
    };
    console.log('sort chec', options.sort);
    console.log('segmentsearch2.buildRequest', request);

    this.safeArray(options.queries).forEach(query => {
      const clause = query.clause?.trim().toLowerCase();
      if (!clause || !request.body.query.bool[clause] || !query.query) {
        throw new Error('Invalid query entry.');
      }
      request.body.query.bool[clause].push(query.query);
    });

    this.safeArray(options.criteria).forEach(criteria => {
      const clause = this.buildCriteriaQuery(criteria);
      request.body.query.bool[criteria.clause].push(clause);
    });
    const timeRangeQuery = this.buildTimeRangeQuery(options);
    if (timeRangeQuery !== null) {
      request.body.query.bool.filter.push(timeRangeQuery);
    }

    return request;
  }

  buildIndexNames(timeRange: any): string[] {
    const currentDate = moment();
    const endDate = currentDate.clone().add(this.INDEX_STEP, 'seconds');
    const indexNames: string[] = [];

    while (currentDate.isBefore(endDate)) {
      const formattedDate = currentDate.format(this.INDEX_DATE_FORMAT[0]);
      indexNames.push(this.INDEX_TEMPLATE.replace(this.INDEX_TEMPLATE_VAR, formattedDate));
      currentDate.add(this.INDEX_STEP, 'seconds');
    }

    return indexNames;
  }

  /**
   * Creates a single Elasticsearch query based off of an entire criteria group from the options object.
   *
   * @param {any} criteriaGroup The criteria group from options.
   * @returns {any} An Elasticsearch query representing this group.
   * @throws Error if the criteria group has invalid options.
   */
  buildCriteriaQuery(criteriaGroup: any): any {
    if (typeof criteriaGroup !== 'object' || criteriaGroup === null) {
      throw new Error('The criteria argument must be an object.');
    }

    criteriaGroup.path = typeof criteriaGroup.path === 'string' ? criteriaGroup.path.trim() : '';
    criteriaGroup.clause = typeof criteriaGroup.clause === 'string' ? criteriaGroup.clause.trim().toLowerCase() : '';
    criteriaGroup.joinType = typeof criteriaGroup.joinType === 'string' ? criteriaGroup.joinType.trim().toLowerCase() : '';

    if (criteriaGroup.path.length === 0) {
      throw new Error('Each criteria must have a path specified.');
    } else if (criteriaGroup.clause.length === 0) {
      throw new Error('Each criteria must specify a clause.');
    } else if (!this.BOOL_CLAUSES[criteriaGroup.clause]) {
      throw new Error('The criteria entry must specify a valid query clause, got: ' + criteriaGroup.clause + '.');
    } else if (criteriaGroup.joinType.length === 0) {
      throw new Error('The criteria entry must specify a joinType.');
    } else if (criteriaGroup.joinType !== 'and' && criteriaGroup.joinType !== 'or') {
      throw new Error('The criteria joinType must be "and" or "or", got: ' + criteriaGroup.joinType + '.');
    } else if (!Array.isArray(criteriaGroup.criteria) || criteriaGroup.criteria.length === 0) {
      throw new Error('The criteria property in a criteria group must be an array and not empty.');
    }

    const defaultGroup: any[] = [];
    const groups: { [key: string]: any[] } = {};

    for (const criteria of criteriaGroup.criteria) {
      if (typeof criteria.fields !== 'object' || criteria.fields === null) {
        throw new Error('Each criteria group\'s criteria must have a fields object defined.');
      } else if (Object.keys(criteria.fields).length === 0) {
        throw new Error('Each criteria group\'s criteria fields object must have at least one property defined.');
      }

      const musts = Object.keys(criteria.fields).map((field) => {
        const value = criteria.fields[field];
        return {
          query_string: {
            query: value,
            analyze_wildcard: typeof value === 'string' && value.includes('*'),
            default_field: `${criteriaGroup.path}.${field}`,
            default_operator: 'AND'
          }
        };
      });

      const query = {
        nested: {
          path: criteriaGroup.path,
          query: {
            bool: {
              must: musts
            }
          }
        }
      };

      if (typeof criteria.group !== 'string') {
        defaultGroup.push(query);
      } else {
        const groupName = criteria.group.trim().toUpperCase();
        if (!Array.isArray(groups[groupName])) {
          groups[groupName] = [];
        }
        groups[groupName].push(query);
      }
    }

    const clauses: any[] = [...defaultGroup];

    for (const groupName of Object.keys(groups)) {
      const groupClauses = groups[groupName];
      if (this.invertJoinType(criteriaGroup.joinType) === 'and') {
        clauses.push({
          bool: {
            must: groupClauses
          }
        });
      } else {
        clauses.push({
          bool: {
            should: groupClauses
          }
        });
      }
    }

    if (criteriaGroup.joinType === 'and') {
      return {
        bool: {
          must: clauses
        }
      };
    } else {
      return {
        bool: {
          should: clauses
        }
      };
    }
  }

  /**
   * Helper function to invert join type
   * @param joinType The join type to invert
   * @returns 'and' or 'or' based on the inversion
   */
  private invertJoinType(joinType: string): string {
    return joinType === 'and' ? 'or' : 'and';
  }


  safeArray(value: any): any[] {
    return Array.isArray(value) ? value : [];
  }

  buildTimeRangeQuery(options: any): any {
    let range = this.getRange(options);
    // If the range is null that means it is forever, so no filter.
    if (range === null) {
      return null;
    }
    if (options.exportGTE !== undefined && options.exportGTE > range.start.valueOf()) {
      return {
        range: {
          "isa.timestampInMillis": {
            gte: range.start.valueOf(),
            lte: options.exportGTE
          }
        }
      };
    } else if (options.exportGTE != null && options.exportGTE <= range.start.valueOf()) {
      return {
        range: {
          "isa.timestampInMillis": {
            gt: range.start.valueOf(),
            lt: range.start.valueOf()
          }
        }
      };
    }
    return {
      range: {
        "isa.timestampInMillis": {
          gte: range.start.valueOf(),
          lte: range.end.valueOf()
        }
      }
    };
  }

  getRange(options: Criteria) {
  const  timeRange  = options;

  if (
    options.timeRange.relative === null &&
    (options.start === null &&
    options.end === null)
  ) {
    throw new Error('You must specify a relative or start and end property on the timeRange options.');
  } else if (
      options.timeRange.relative !== null &&
    (options.start !== null || options.end !== null)
  ) {
    throw new Error('The timeRange object must specify a relative property or a start and end property, but not both.');
  }

  // Handle relative range case
  if (options.timeRange.relative !== null) {
    if (typeof options.timeRange.relative !== 'number') {
      throw new Error(`The relative timeRange value must be a number, got: ${options.timeRange.relative}.`);
    }

    return {
      start: moment().subtract(Math.abs(options.simple.timeRange), 'seconds').startOf('day'),
      end: moment().endOf('day'),
    };
  }

  // Handle specific start and end date range case
  if (options.start !== null && options.end !== null) {
    const start = moment(options.start).startOf('day');
    const end = moment(options.end).endOf('day');

    if (start > end) {
      throw new Error('The timeRange start must be before the timeRange end.');
    }

    return {
      start,
      end,
    };
  }

  // Handle cases where start or end is missing
  if (options.start === null) {
    throw new Error('The timeRange object must specify a start property if end is specified.');
  } else {
    throw new Error('The timeRange object must specify an end property if start is specified.');
  }
}



}
