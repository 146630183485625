<mat-tab-group>
  <mat-tab>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="margin-top: 5px;">

      <!-- Left Column -->
      <div fxFlex="50%">
        <div fxLayout="row" fxLayoutGap="20px">

          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Document Type</mat-label>
              <mat-select [(ngModel)]="criteria.simple.documentType" (selectionChange)="setDocumentType($event.value)">
                <mat-option value="purbol">Pickup Request</mat-option>
                <mat-option value="invoice">Invoice</mat-option>
                <mat-option value="status">Shipment Status</mat-option>
                <mat-option value="delimani">Delivery Manifest</mat-option>
                <mat-option value="any">Any</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>ISA Sender ID</mat-label>
              <input matInput [(ngModel)]="criteria.simple.isaSenderId"/>
            </mat-form-field>
          </div>

          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>ISA Receiver ID</mat-label>
              <input matInput [(ngModel)]="criteria.simple.isaReceiverId"/>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" style="margin-top: 20px;">
          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Document Region</mat-label>
              <mat-select [(ngModel)]="criteria.simple.usageInd">
                <mat-option value="any">Test and Production</mat-option>
                <mat-option value="p">Production</mat-option>
                <mat-option value="t">Test</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>GS Sender ID</mat-label>
              <input matInput [(ngModel)]="criteria.simple.gsSenderId"/>
            </mat-form-field>
          </div>

          <div fxFlex="33%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>GS Receiver ID</mat-label>
              <input matInput [(ngModel)]="criteria.simple.gsReceiverId"/>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" style="margin-top: 20px;">
          <div fxFlex="70%">
            <mat-form-field appearance="outline" *ngIf="criteria.simple.timeRange !== 'custom'" style="width: 100%;">
              <mat-label>Time Range</mat-label>
              <mat-select [(ngModel)]="criteria.simple.timeRange" aria-label="time range filter">
                <mat-option value="disabled">Limit the results to a time range</mat-option>
                <mat-option value="86400">Last 24 hours</mat-option>
                <mat-option value="172800">Last 48 hours</mat-option>
                <mat-option value="604800">Last 7 days</mat-option>
                <mat-option value="2592000">Last 30 days</mat-option>
                <mat-option value="7776000">Last 90 days</mat-option>
                <mat-option value="15552000">Last 180 days</mat-option>
                <mat-option value="31536000">Last 365 days</mat-option>
                <mat-option value="78840000">Forever</mat-option>
                <mat-option value="custom">Custom date range...</mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="criteria.simple.timeRange === 'custom'">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="startPicker" [(ngModel)]="criteria.simple.startTimeRange" [max]="criteria.simple.endTimeRange" required/>
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="endPicker" [(ngModel)]="criteria.simple.endTimeRange" [min]="criteria.simple.startTimeRange" [max]="criteria.simple.maxEndTime" required/>
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
              <button mat-icon-button (click)="criteria.simple.timeRange = '86400'" title="Switch back to time range options">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>

          <div fxFlex="30%">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Order By</mat-label>
              <mat-select [(ngModel)]="criteria.simple.orderBy" aria-label="sort">
                <mat-option value="disabled">Sort order</mat-option>
                <mat-option value="isa.timestamp:desc">ISA timestamp (Newest to Oldest)</mat-option>
                <mat-option value="isa.timestamp:asc">ISA timestamp (Oldest to Newest)</mat-option>
                <mat-option value="_score">Match Score</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <!-- Reference Numbers Section -->
        <div style="margin-top: 20px;">
          <h6>Reference Numbers
            <button mat-icon-button aria-label="Help" (click)="openHelp('reference')">
              <mat-icon>help_outline</mat-icon>
            </button>
          </h6>
          <p style="font-size: 80%; margin-top: 0;">Limit results based on reference numbers, such as <strong>PRO</strong> for XPO&#039;s PRO, <strong>SN#</strong> for EDI sender shipment ID and any other reference number.</p>

          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
            <div *ngFor="let referenceNumber of criteria.simple.referenceNumbers" fxFlex="45%">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Type, e.g. SN#</mat-label>
                <input matInput [(ngModel)]="referenceNumber.type" title="The reference number type, to do multiple use a comma, e.g. SN#, SN"/>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Reference Number</mat-label>
                <input matInput [(ngModel)]="referenceNumber.value" title="The reference number value to search for"/>
              </mat-form-field>
              <button mat-icon-button color="warn" (click)="removeReferenceNumber(referenceNumber)" title="Remove this reference number">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>

            <div fxFlex="10%">
              <button mat-icon-button color="primary" aria-label="Add reference number" (click)="addReferenceNumber()">
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>

      </div>



      <!-- Right Column -->
      <div class="row" fxFlex="50%">
        <div class="col m6 s12">
          <div class="row" fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="space-between stretch">
            <div class="col m4 s12">
              <h6>Shipper</h6>
              <party-criteria [partyType]="'Shipper'" [party]="criteria.simple.shipper"></party-criteria>
            </div>
            <div class="col m4 s12">
              <h6>Consignee</h6>
              <party-criteria [partyType]="'Consignee'" [party]="criteria.simple.consignee"></party-criteria>
            </div>
            <div class="col m4 s12">
              <h6>Bill To</h6>
              <party-criteria [partyType]="'BillTo'" [party]="criteria.simple.billTo"></party-criteria>
            </div>
          </div>
        </div>
      </div>



    </div>


  </mat-tab>
</mat-tab-group>
