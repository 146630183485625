import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class SearchResultQueueService {
  private resultIds: Array<{ index: string; type: string; id: string }> = [];
  private currentIndex: number = -1;

  constructor(private navigation: NavigationService) {
    const storedResultIds = sessionStorage.getItem('resultIds');
    const storedCurrentIndex = sessionStorage.getItem('currentIndex');

    this.resultIds = storedResultIds ? JSON.parse(storedResultIds) : [];
    this.currentIndex = storedCurrentIndex ? parseInt(storedCurrentIndex, 10) : -1;
  }

  // Public methods
  setResultIds(ids: Array<{ index: string; type: string; id: string }>): void {
    this.resultIds = Array.isArray(ids) ? ids : [];
    sessionStorage.setItem('resultIds', JSON.stringify(this.resultIds));
  }

  setResults(results: any[], currentIndex: number): void {
    const ids = results.map(entry => ({
      index: entry.meta.index,
      type: entry.meta.type,
      id: entry.meta.id
    }));
    this.setResultIds(ids);
    this.setCurrentIndex(currentIndex);
  }

  getNext(): { index: string; type: string; id: string } | undefined {
    if (!this.hasNext()) {
      return undefined;
    }
    return this.resultIds[this.currentIndex + 1];
  }

  goToNext(page: string): void {
    const next = this.getNext();
    if (!next) {
      throw new Error('There is no next result to view.');
    }
    this.goto(page, next, 1);
  }

  hasNext(): boolean {
    return this.resultIds.length > 0 && (this.currentIndex + 1) < this.resultIds.length;
  }

  getNextCount(): number {
    if (!this.hasNext()) {
      return 0;
    }
    return this.resultIds.length - this.currentIndex - 1;
  }

  getPrev(): { index: string; type: string; id: string } | undefined {
    if (!this.hasPrev()) {
      return undefined;
    }
    return this.resultIds[this.currentIndex - 1];
  }

  goToPrev(page: string): void {
    const prev = this.getPrev();
    if (!prev) {
      throw new Error('There is no previous result to view.');
    }
    this.goto(page, prev, -1);
  }

  hasPrev(): boolean {
    return this.resultIds.length > 0 && (this.currentIndex - 1) >= 0;
  }

  getPrevCount(): number {
    if (!this.hasPrev()) {
      return 0;
    }
    return this.currentIndex;
  }

  private getResultIds(): Array<{ index: string; type: string; id: string }> {
    return this.resultIds;
  }

  private getCurrentIndex(): number {
    return this.currentIndex;
  }

  private setCurrentIndex(index: number): void {
    if (index < 0) {
      throw new Error('The index must be > 0.');
    }
    this.currentIndex = index;
    sessionStorage.setItem('currentIndex', this.currentIndex.toString());
  }

  private addToCurrentIndex(delta: number): void {
    this.setCurrentIndex(this.currentIndex + delta);
  }

  private goto(page: string, result: { index: string; type: string; id: string }, delta: number): void {
    if (page === 'inbound') {
      this.navigation.gotoInboundView(result.index, result.type, result.id);
      this.addToCurrentIndex(delta);
    } else if (page === 'compare') {
      this.navigation.gotoCompareView(result.index, result.type, result.id);
      this.addToCurrentIndex(delta);
    } else {
      throw new Error(`Unknown page "${page}".`);
    }
  }
}
