import { Component } from '@angular/core';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { EdiPreviewService } from './services/edipreview.service';
import { EdiPreviewComponent } from './edipreview/edi-preview.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  build: string;

  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService

  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

  }
}
