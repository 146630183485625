<div class="card with-padding no-capture">
  <div class="row">
    <div class="col m2 s4" style="position: relative;">
      <mat-form-field appearance="outline">
        <mat-label>Document Type</mat-label>
        <mat-select [(ngModel)]="tranType">
          <mat-option [value]="'invoice'">Invoice</mat-option>
          <mat-option [value]="'status'">Shipment Status</mat-option>
          <mat-option [value]="'pur'">Pickup Requests</mat-option>
          <mat-option [value]="'bol'">Bill of Lading</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- ID Type for 'status' -->
      <mat-form-field appearance="outline" *ngIf="tranType === 'status'">
        <mat-label>ID Type</mat-label>
        <mat-select [(ngModel)]="idType">
          <mat-option [value]="'ref'">Reference#</mat-option>
          <mat-option [value]="'pro'">Pro Number</mat-option>
          <mat-option [value]="'ship'">Shipment ID</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- ID Type for 'pur' and 'bol' -->
      <mat-form-field appearance="outline" *ngIf="tranType === 'pur' || tranType === 'bol'">
        <mat-label>ID Type</mat-label>
        <mat-select [(ngModel)]="idType">
          <mat-option [value]="'ref'">Reference#</mat-option>
          <mat-option [value]="'ship'">Shipment ID</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Reference Code -->
      <mat-form-field appearance="outline" *ngIf="idType === 'ref' && (tranType === 'status' || tranType === 'pur' || tranType === 'bol')">
        <mat-label>Reference Code</mat-label>
        <input matInput [(ngModel)]="refCode" id="refCode" type="text" />
      </mat-form-field>
    </div>

    <div class="col m8 s16">
      <mat-form-field appearance="outline">
        <!-- Conditional Label -->
        <mat-label *ngIf="tranType !== 'invoice' && idType === 'ship'">Enter the Shipment IDs to generate a report for...</mat-label>
        <mat-label *ngIf="tranType !== 'invoice' && idType === 'ref'">Enter the Reference #'s to generate a report for...</mat-label>
        <mat-label *ngIf="tranType === 'invoice' || (idType === 'pro' && tranType === 'status')">Enter the Pros to generate a report for...</mat-label>

        <input matInput [(ngModel)]="ids" id="ids" type="text" />

        <!-- Help Button -->
        <button mat-icon-button aria-label="Help" (click)="openHelp('reports')" style="position: absolute; top: 20px; right: 10px;">
          <mat-icon>help_outline</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col m2 s4">
      <mat-form-field appearance="outline">
        <mat-label for="email">Enter the email recipient</mat-label>
        <input matInput [(ngModel)]="email" id="email" type="text" />
      </mat-form-field>
    </div>

    <div class="col m2 s12">
      <button mat-raised-button color="primary" class="width100" (click)="post()">
        <mat-icon>assignment</mat-icon>
        Generate Report
      </button>
    </div>
  </div>
</div>
