import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DataApiModule } from '@xpo-ltl/data-api';
import { MainComponent } from './main/main.component';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule } from '@angular/common/http'; // Import this line
import { MatFormFieldModule } from '@angular/material/form-field';
import { PartyCriteriaComponent } from './search/criteria/party/party-criteria.component';
import { SearchCriteriaComponent } from './search/criteria/search-criteria.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { SimpleTabComponent } from './search/criteria/simple-tab.component';
import { SegmentReportComponent } from './search/criteria/segment-report.component';
import { SearchComponent } from './search/search.component';
import { WebResultsComponent } from './search/web-results.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EdiPreviewComponent } from './edipreview/edi-preview.component';
import { HighlightEdiComponent } from './edipreview/highlight-edi.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PartyCriteriaComponent,
    SearchCriteriaComponent,
    SimpleTabComponent,
    SegmentReportComponent,
    SearchComponent,
    WebResultsComponent,
    EdiPreviewComponent,
    HighlightEdiComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    ConfigManagerModule,
    DataApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    XpoShellModule,
    XpoFooterModule,
    XpoCardModule,
    XpoLtlAuthUiModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    FlexLayoutModule,
  ],
  exports: [
    SearchComponent,
    SearchCriteriaComponent,
    PartyCriteriaComponent,
    WebResultsComponent,
    SimpleTabComponent,
    EdiPreviewComponent,
    HighlightEdiComponent
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
