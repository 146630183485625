import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchUtilsService } from './searchutils.service'; // Adjust the path as needed

@Injectable({
  providedIn: 'root',
})
export class DocumentIdsSearchService {
  constructor(private searchUtils: SearchUtilsService) {}

  /**
   * Performs a search which will retrieve results for documents based on their
   * document ID.
   */
  search(request: any): Observable<any> {
    const searchRequest = this.getRequest(request);
    return this.searchUtils.search(searchRequest);
  }

  /**
   * Returns an object representing the search query to execute to locate the
   * documents by their document ID.
   */
  private getRequest(request: any): any {
    return {
      index: 'b2b_edi_*',
      size: request.documentIds.length,
      body: {
        sort: ['_score'],
        query: {
          query_string: {
            query: this.expand('meta.documentId', request.documentIds),
          },
        },
      },
    };
  }

  /**
   * Takes a field name and an array of values and returns a string which filters
   * for all the specified values against the single field.
   */
  private expand(fieldName: string, values: string[]): string {
    const queries = values.map((value) =>
      `${fieldName}:"${value.replace('"', '\\"')}"`
    );
    return queries.join(' OR ');
  }
}
