import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private LOG_LEVEL = 3;

  constructor() { }

  debug(...args: any[]): void {
    this.logWith(1)(...args);
  }

  info(...args: any[]): void {
    this.logWith(2)(...args);
  }

  log(...args: any[]): void {
    this.logWith(2)(...args); // Alias for info
  }

  warn(...args: any[]): void {
    this.logWith(3)(...args);
  }

  error(...args: any[]): void {
    this.logWith(4)(...args);
  }

  fatal(...args: any[]): void {
    this.logWith(5)(...args);
  }

  private logWith(levelNumber: number): (...args: any[]) => void {
    if (levelNumber < this.LOG_LEVEL) {
      return (...args) => {}; // Do nothing if the level is too low to log
    }

    return (...args) => this.reallyLog(levelNumber, args);
  }

  private reallyLog(level: number, args: any[]): void {
    let levelLabel = `[${this.getLevelLabel(level)}]`;

    if (args.length === 0 || typeof args[0] !== 'string') {
      args.unshift(levelLabel);
    } else {
      args[0] = `${levelLabel} ${args[0]}`;
    }

    this.getLogger(level)(...args);
  }

  private getLogger(level: number): (...args: any[]) => void {
    if (level === 3) {
      return console.warn;
    } else if (level === 4 || level === 5) {
      return console.error;
    } else if (level === 2) {
      return console.info;
    } else {
      return console.log;
    }
  }

  private getLevelLabel(level: number): string {
    const LEVELS = {
      1: 'DEBUG',
      2: 'INFO',
      3: 'WARN',
      4: 'ERROR',
      5: 'FATAL'
    };
    return LEVELS[level] || `LEVEL: ${level}`;
  }
}
