<div id="edi-preview-container">
  <div class="flex-row-container preview-header">
    <div class="flex-column-container">
      <p class="title">{{ getPanelTitle() }}</p>
      <p class="sub-title" *ngIf="getPanelSubTitle().length > 0">{{ getPanelSubTitle() }}</p>
    </div>
    <span class="flex-spacer"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon aria-label="Close dialog">close</mat-icon>
    </button>
  </div>

  <div class="edi-preview-content">
    <div class="flex-row-container">
      <span class="flex-spacer"></span>
      <button mat-button (click)="switchMode()">
        <mat-icon>{{ mode === 'text' ? 'format_align_right' : 'text_fields' }}</mat-icon>
        {{ mode === 'text' ? 'View Formatted' : 'View as Text' }}
      </button>
    </div>

    <!-- Display formatted content -->
    <div *ngIf="mode === 'formatted'" class="content-box">
      <highlight-edi [data]="ediSegments"></highlight-edi>
    </div>

    <!-- Display text content -->
    <div *ngIf="mode === 'text'" class="content-box">
      <textarea readonly [(ngModel)]="ediText"></textarea>
    </div>

    <!-- Parties Section -->

    <div>
      <div class="card-header with-margin" style="margin-left: -5px; margin-right: -5px;">
        Parties
      </div>

      <table style="width: 100%;">
        <tr *ngFor="let party of data.parties">
          <td style="vertical-align: top;"><strong>{{ party.type }}:</strong></td>
          <td style="vertical-align: top;">{{ party.text }}</td>
        </tr>
      </table>

      <!-- Reference Numbers Section -->
      <div class="card-header with-margin" style="margin-left: -5px; margin-right: -5px;">
        Reference Numbers
      </div>

      <table style="width: 100%;">
        <tr *ngFor="let typeCd of getReferenceNumberKeys(data.referenceNumbers)">
          <td style="vertical-align: top;"><strong>{{ typeCd }}:</strong></td>
          <td style="vertical-align: top;">
            {{ data.referenceNumbers[typeCd]?.join(', ') || 'No reference numbers' }}
          </td>
        </tr>
      </table>
    </div>

    <div style="height: 15px;"></div>
  </div>
</div>
