import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonUtilsService {

  constructor() { }

  toUrlJson(object: any): string {
    if (!object || typeof object !== 'object') {
      return JSON.stringify({});
    }

    // Detach it...
    let detached = JSON.parse(JSON.stringify(object));

    // Remove junk properties, which are empty or $$hashKey (which is an AngularJS tracking property).
    this.removeEmptyProperties(detached);

    return JSON.stringify(detached);
  }

  private removeEmptyProperties(object: any): void {
    if (!object || typeof object !== 'object') {
      return;
    } else if (Array.isArray(object)) {
      for (let i = object.length - 1; i >= 0; i--) {
        if (this.isEmptyObject(object[i])) {
          object.splice(i, 1);
        } else {
          this.removeEmptyProperties(object[i]);
        }
      }
    } else {
      Object.keys(object).forEach(key => {
        const value = object[key];

        // Delete this, as it is an AngularJS tracking property. Also remove undefined, null and empty strings.
        if (key === '$$hashKey' || value === undefined || value === null || (typeof value === 'string' && value.length === 0)) {
          delete object[key];
        }
        // Recurse if we need to.
        else if (typeof value === 'object') {
          this.removeEmptyProperties(value);
        }
      });
    }
  }

  isValidJson(json: string): boolean {
    if (typeof json !== 'string') {
      return false;
    }

    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  }

  parseJson(json: string, transformer: ((key: string, value: any) => any) | null): any {
    if (transformer !== null && typeof transformer !== 'function') {
      throw new Error('The transformer should be a function, got: ' + (typeof transformer) + '.');
    }

    let object = JSON.parse(json);
    if (!object || typeof object !== 'object') {
      return object;
    }

    return this.transform(object, transformer);
  }

  private transform(object: any, transformer: ((key: string, value: any) => any) | null): any {
    if (!object || typeof object !== 'object' || !transformer) {
      return object;
    }

    if (Array.isArray(object)) {
      object.forEach((entry, index) => {
        object[index] = this.transform(entry, transformer);
      });
    } else {
      Object.keys(object).forEach(key => {
        const value = object[key];
        if (typeof value === 'object') {
          object[key] = this.transform(value, transformer);
        } else {
          object[key] = transformer(key, value);
        }
      });
    }

    return object;
  }

  isJsonObject(json: string): boolean {
    try {
      const object = JSON.parse(json);
      return typeof object === 'object' && !Array.isArray(object);
    } catch (e) {
      return false;
    }
  }

  private isEmptyObject(obj: any): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
