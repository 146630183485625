import { Component,OnInit  } from '@angular/core';
import {BatchTaskService} from '../services/batchtask.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  createdTask: any;

  constructor(private batchTaskService: BatchTaskService) { }

  ngOnInit(): void {
   // const taskName = 'EDI_OUTBOUND_SEND_FLOW_REPORT'; // Change this to the desired task name
    //const args = { pro : '11111111', email : 'daniel.herren@xpo.com' };

    //console.log(this.createTask(taskName, args));
  }

  /* createTask(): void {
    const taskName = 'EDI_OUTBOUND_SEND_FLOW_REPORT'; // Change this to the desired task name
    const args = { pro : '11111111', email : 'daniel.herren@xpo.com' };

   this.batchTaskService.create(taskName, args).subscribe(
      response => {
        this.createdTask = response;
      },
      error => {
        console.error('Error creating task:', error);
      }
    );*/
    async createTask(taskName, args): Promise<void> {
      try {
        // Call the index method from IndexHandlerService
        await this.batchTaskService.create(taskName, args);
        console.log('Task creation success');
        // Handle success conditions here
      } catch (error) {
        console.error('Task creation error:', error);
        // Handle error conditions here
      }
    }

}
