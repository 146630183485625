import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private initialized = false;
  private titleSubject = new BehaviorSubject<string>('');
  public title$ = this.titleSubject.asObservable();  // Observable for components to subscribe

  constructor(private router: Router) {
    this.init();
  }

  /**
   * Initializes the service.
   */
  private init(): void {
    if (this.initialized) {
      return;
    }

    // Listen to route changes and reset the title on each navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.setTitle('');
      }
    });

    this.initialized = true;
  }

  /**
   * Sets the title of the page.
   * @param title The title of the page.
   */
  setTitle(title: string): void {
    this.titleSubject.next(title);
  }
}
