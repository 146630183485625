import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdiUtilsNodeService {

  private INDENT_SEGMENTS = {
    'GS': true,
    'ST': true
  };

  private UNDENT_SEGMENTS = {
    'GE': true,
    'SE': true
  };

  /**
   * Returns an array with each entry being a segment, with a segmentId,
   * elements array and indent property.
   */
  getSegments(data: any): any[] | undefined {
    let separators = null;
    if (typeof data === 'object') {
      separators = data.separators;
      data = data.data;
    }

    data = data.trim();
    if (separators === null) {
      data = data.replace(/[\n|\r]/g, '');
    }

    if (data.indexOf('ISA') > 0) {
      return undefined;
    }

    separators = separators !== null ? separators : this.getSeparators(data);

    const lines = data.split(separators.segment);
    const segments: any[] = [];
    const stack: string[] = [];

    for (let index = 0; index < lines.length; index++) {
      let line = lines[index];
      if (index + 1 === lines.length && line.trim().length === 0) {
        continue;
      }

      const elements = line.split(separators.element);
      const segmentId = elements[0].trim();
      if (segmentId.trim().length === 0) {
        continue;
      }

      if (this.isDefined(this.INDENT_SEGMENTS[segmentId])) {
        stack.push(segmentId);
      }

      const segment = {
        segmentId: segmentId.trim().toUpperCase(),
        elements: elements.slice(1),
        line: line + separators.segment,
        indent: stack.length,
        parentId: stack.length > 0 ? stack[stack.length - 1] : segmentId,
        separators: separators
      };

      segments.push(segment);

      if (this.isDefined(this.UNDENT_SEGMENTS[segmentId])) {
        stack.pop();
      }
    }

    return segments;
  }

  /**
   * Returns all the values at the element index within the specified segment.
   */
  getSegmentValues(data: any, segmentId: string, elementIndex: number): any[] {
    const segments = this.getSegments(data);
    const values: any[] = [];
    segmentId = segmentId.trim().toUpperCase();

    if (segments) {
      segments.forEach(segment => {
        if (segment.segmentId === segmentId && elementIndex < segment.elements.length) {
          values.push(segment.elements[elementIndex]);
        }
      });
    }

    return values;
  }

  /**
   * Returns a difference between two sets of EDI data, line by line.
   */
  getDiff(left: any, right: any): any[] | undefined {
    if (!this.isDefined(left) || !this.isDefined(right)) {
      return undefined;
    }

    const leftSegments = this.getSimpleSegments(left, this.getSeparators(left));
    const rightSegments = this.getSimpleSegments(right, this.getSeparators(right));

    const max = Math.max(leftSegments.length, rightSegments.length);
    const diff: any[] = [];

    for (let index = 0; index < max; index++) {
      const leftSegment = index >= leftSegments.length ? null : leftSegments[index];
      const rightSegment = index >= rightSegments.length ? null : rightSegments[index];

      diff.push(this.getSegmentDiff(leftSegment, rightSegment));
    }

    return diff;
  }

  private getSegmentDiff(left: any, right: any): any {
    if (left === null || right === null) {
      return undefined;
    }

    const max = Math.max(left.length, right.length);
    const diff: any[] = [];

    for (let index = 0; index < max; index++) {
      const leftElement = index >= left.length ? null : left[index];
      const rightElement = index >= right.length ? null : right[index];

     // diff.push(this.getElementDiff(leftElement, rightElement));
    }

    return diff;
  }

  private getElementDiff(left: any, right: any): any {
    if (left === null) {
      return {
        change: 'added',
        value: right
      };
    } else if (right === null) {
      return {
        change: 'removed',
        value: left
      };
    }

    return {
      change: left.toLowerCase().trim() === right.toLowerCase().trim() ? 'none' : 'changed',
      value: right
    };
  }

  private getSimpleSegments(data: any, separators: any): any[] {
    const lines = data.split(separators.segment);
    const segments: any[] = [];

    lines.forEach(line => {
      line = line.trim();
      if (line.length > 0) {
        //segments.push(this.getElements(line, separators));
      }
    });

    return segments;
  }

  private getElements(line: string, separators: any): string[] {
    return line.split(separators.element);
  }

  private getSeparators(data: string): any {
    return {
      segment: data.substring(105, 106),
      element: data.substring(3, 4),
      subElement: data.substring(104, 105)
    };
  }

  private isDefined(val: any): boolean {
    return typeof val !== 'undefined';
  }
}
