<p *ngIf="selectionEnabled" class="select-all">
  <mat-checkbox [(ngModel)]="isSelectAll" (click)="selectAll()" aria-label="Select all"></mat-checkbox> Select all
</p>

<div *ngFor="let segment of segments; trackBy: segmentTracker; let i = index" class="seg-container">
  <p
    class="segment-row line-no-{{i + 1}} segment-id-{{segment.segmentId}}"
    [ngClass]="{'selected': selected ? selected[i] : false}"
    [attr.data-lineno]="i + 1"
    [attr.data-segment-id]="segment.segmentId">

    <mat-checkbox *ngIf="selectionEnabled" [(ngModel)]="selected[i]" aria-label="Select this segment"></mat-checkbox>

    <span class="line-no">{{ i + 1 }}</span>

    <span class="segment-data" [ngStyle]="{'padding-left.px': segment.indent * 10}">
            <span class="element" data-element-no="0">{{ segment.segmentId }}</span>
      {{ segment.elements.length === 0 ? segment.separators.segment : segment.separators.element }}

      <ng-container *ngFor="let element of segment.elements; let j = index; trackBy: elementTracker">
                <span class="element"
                      [attr.title]="segment.segmentId + (j + 1 >= 10 ? (j + 1) : '0' + (j + 1))"
                      [attr.data-element-no]="j + 1">
                    {{ element }}
                </span>
        {{ j === segment.elements.length - 1 ? '' : segment.separators.element }}
            </ng-container>

      {{ segment.separators.segment }}
        </span>
  </p>
</div>

<p *ngIf="segments.length === 0" style="color: red;">
  No Segments
</p>
