import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private router: Router) {}

  /**
   * Routes the user to the comparison view of the document.
   */
  gotoCompareView(index: string, type: string, id: string): void {
    this.router.navigateByUrl(`/compareview/${index}/${type}/${id}`);
  }

  gotoInboundView(index: string, type: string, id: string): void {
    this.router.navigateByUrl(`/inboundview/${index}/${type}/${id}`);
  }

  gotoProView(gsId: string, tranSet: string, proNbr: string): void {
    this.router.navigateByUrl(`/proview/${encodeURIComponent(gsId)}/${tranSet}/${proNbr}`);
  }

  goToSearch(restore?: boolean): void {
    this.router.navigateByUrl(`/search${restore ? '/restore' : ''}`);
  }

  gotoEdiSegmentReport(pros: string, email: string): void {
    this.router.navigateByUrl(`/edisegmentreport/${encodeURIComponent(pros)}/${email}`);
  }
}
