import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetUtilsService } from '../util/getutils.service';
import { CompareviewService } from '../services/compareview.service';
import { ProgressService } from '../util/progress.service';
import { LogService } from './log.service';
import { EdiPreviewComponent } from '../edipreview/edi-preview.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EdiPreviewService {
  constructor(
    private dialog: MatDialog,
    private getUtilsService: GetUtilsService,
    private compareViewService: CompareviewService,
    private progressService: ProgressService,
    private logService: LogService
  ) {}

  preview(document: any): void {
    this.progressService.showLoading();

    const formattedDocument = this.formatData(document);

    this.getUtilsService.get(formattedDocument.index, formattedDocument.type, formattedDocument.id)
      .pipe(
        catchError((error) => {
          this.logService.error(error);
          this.progressService.hideLoading();
          alert(error);
          return of(null);  // Return a fallback observable in case of error
        })
      )
      .subscribe((response: any) => {
        if (response) {
          const formattedData = this.compareViewService.formatDocument({
            source: response._source,
          });

          // Pass the formatted document data to the EdiPreviewComponent via MatDialog
          this.dialog.open(EdiPreviewComponent, {
            width: '500px',
            data: {
              document: response._source,
              formatted: formattedData,
              response: response
            },
          });

          this.progressService.hideLoading();
        }
      });
  }

  close(): void {
    this.dialog.closeAll();
  }

  private formatData(document: any): any {
    if (typeof document !== 'object') {
      this.logService.error('Expecting object', document);
      throw new Error('Expected the argument to be an object.');
    }

    if (document.meta && document.meta.id && document.meta.type && document.meta.index) {
      return document.meta;
    } else {
      this.logService.error('Document passed:', document);
      throw new Error('Unknown document format, check logs for details');
    }
  }
}
