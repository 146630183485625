import {Component, EventEmitter, Output, Input} from '@angular/core';

@Component({
  selector: 'segment-report',
  templateUrl: './segment-report.template.html',
})
export class SegmentReportComponent {
  @Input() tranType: string;
  @Input() idType: string ;
  @Input() refCode: string;
  @Input() ids: string;
  @Input() email: string;

  @Output() onPost: EventEmitter<void> = new EventEmitter();
  @Output() onOpenHelp: EventEmitter<string> = new EventEmitter();
  // This method should contain the logic for generating the report
  post(): void {
    // Handle the report generation logic here
    console.log('Generating report with the following data:', {
      tranType: this.tranType,
      idType: this.idType,
      refCode: this.refCode,
      ids: this.ids,
      email: this.email
    });

    this.onPost.emit();
  }

  // This method should handle the help dialog or instructions
  openHelp(section: string): void {
    this.onOpenHelp.emit(section);
  }
}
