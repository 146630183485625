import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ElasticSearchService } from '../util/elasticsearch.service';

@Injectable({
  providedIn: 'root'
})
export class BatchTaskService {
  private TASK_INDEX = 'b2b_edi_tasks';
  private TASK_TYPE = 'batch';
  private DEFINED_TASKS = {
    EDI_OUTBOUND_SEND_FLOW_REPORT: true,
    EDI_SHIPMENT_STATUS_SEND_REPORT: true,
    EDI_REPORT: true
  };

  constructor(private elasticsearch: ElasticSearchService) {}

  create(taskName: string, args: any): Observable<any> {
    const document = {
      task: taskName,
      args: this.toArgsMap(args),
      status: 'QUEUED',
      requestedTmst: Date.now()
    };

    // Convert Promise to Observable using `from`
    return from(this.elasticsearch.index(this.TASK_INDEX, this.TASK_TYPE, null, document)).pipe(
      map((response: any) => ({
        created: response.created || response.result === 'updated',
        response: response,
        index: response._index,
        type: response._type,
        id: response._id
      })),
      catchError((error: any) => {
        console.error('Indexing error:', error);
        throw error;
      })
    );
  }

  list(taskName: string, status: string[], max: number): Observable<any[]> {
    if (!this.DEFINED_TASKS[taskName]) {
      throw new Error(`The task ${taskName} is not defined.`);
    } else if (typeof max !== 'number' || max <= 0) {
      throw new Error(`The max must be a number and greater than 0, got: ${max}`);
    }

    status = this.formatStatus(status);

    const searchRequest = {
      index: this.TASK_INDEX,
      type: this.TASK_TYPE,
      body: {
        size: max,
        sort: {
          requestedTmst: 'asc'
        },
        query: this.buildQuery(taskName, status)
      }
    };

    return this.elasticsearch.search(searchRequest).pipe(map(response => this.formatListResponse(response)));
  }

  private toArgsMap(args: any): { [key: string]: string } {
    if (args === null || args === undefined) {
      return {};
    } else if (typeof args !== 'object' || Array.isArray(args)) {
      throw new Error('The args must be an object of key/value string pairs.');
    }

    const map: { [key: string]: string } = {};
    for (const key of Object.keys(args)) {
      const value = args[key];
      if (value !== null && value !== undefined) {
        map[key] = value.toString();
      }
    }

    return map;
  }

  private formatListResponse(response: any): any[] {
    if (!response.hits || !Array.isArray(response.hits.hits)) {
      return [];
    }

    return response.hits.hits.map(hit => this.formatListHit(hit));
  }

  private formatListHit(hit: any): any {
    return {
      task: {
        task: hit._source.task,
        args: hit._source.args || {},
        status: hit._source.status || 'QUEUED',
        requestedTmst: hit._source.requestedTmst,
        lastUpdatedTmst: hit._source.lastUpdatedTmst || hit._source.requestedTmst,
        response: hit._source.response || {},
        percentCompleted: hit._source.percentCompleted || 0
      },
      document: {
        index: hit._index,
        type: hit._type,
        id: hit._id
      }
    };
  }

  private formatStatus(status: string[] | string): string[] {
    if (Array.isArray(status)) {
      return status.map(entry => entry.toUpperCase().trim());
    } else if (typeof status === 'string') {
      return [status.toUpperCase().trim()];
    } else {
      return [];
    }
  }

  private buildQuery(taskName: string, status: string[]): any {
    if (status.length === 0) {
      return {
        query_string: {
          query: `task:${taskName}`
        }
      };
    }

    const filters = status.map(entry => `status:${entry}`);
    return {
      query_string: {
        query: `task:${taskName} AND (${filters.join(' OR ')})`
      }
    };
  }

  private checkArgument(condition: boolean, taskName: string, errorMessage: string): boolean {
    if (!condition) {
      throw new Error(errorMessage);
    }
    return condition;
  }
}
